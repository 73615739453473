import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import App from './App';
import { AuthProvider } from './context/AuthContext';
import '../src/assets/styles/kig.css';



const container = document.getElementById('root'); 
const root = createRoot(container);

//wersja bez debugowania
root.render(
     <AuthProvider>
       <App />
     </AuthProvider>
 );

//root.render(
//  <React.StrictMode>
//    <AuthProvider>
//      <App />
//    </AuthProvider>
//  </React.StrictMode>
//);
