import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ApiHost } from '../../utils/ApiConfig';

function Consent({ consentId }) {
    const [consentDetails, setConsentDetails] = useState(null);
    const { accessToken } = useAuth();
    let consentTypes = ["Zgoda RODO", "Zgoda handlowa"];
    let navigate = useNavigate();

    const handleAccept = (consentType) =>(e) => {
        e.preventDefault();
        navigate('/account/consents/add/'+consentType, { replace: true });
    };

    const handleRevoke = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(ApiHost + `/users/consent/revoke`, {
                consent_id: consentId
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            if (response.status === 200) {
                console.log("Odwołano zgodę: " + consentId)

                setConsentDetails(prevDetails => ({
                    ...prevDetails,
                    Revoked: new Date().toISOString()
                }));
            }
        } catch (error) {
            console.error("Błąd podczas pobierania szczegółów zgody:", error);
        }
    };

    useEffect(() => {
        const fetchConsentDetails = async () => {
            try {
                console.log("Pobieranie danych dla consentId: " + consentId);
                const response = await axios.post(ApiHost + `/users/consent/details`, {
                    consent_id: consentId
                }, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                setConsentDetails(response.data.consent);
            } catch (error) {
                console.error("Błąd podczas pobierania szczegółów zgody:", error);
            }
        };

        fetchConsentDetails();
    }, [consentId, accessToken]); // Zależności useEffect

    if (!consentDetails) {
        return (
            <div className="ms-auto spinner-border" role="status">
                <span className="visually-hidden">Pobieranie danych</span>
                <br />
            </div>
        )
    }
    else {
        return (
            <div className='card container-fluid'>
                <h3>{consentTypes[consentDetails.Type - 1]}</h3>

                <div className="mb-3">{consentDetails.Content}</div>
                {
                    (!consentDetails.Revoked) ? (
                        <form onSubmit={handleRevoke}>
                            <div className="d-flex flex-row flex-wrap">
                                <div className='me-2 align-self-end'>Zaakceptowano: </div>
                                <div className='me-2 align-self-end'><strong>{new Date(consentDetails.CreateDate).toLocaleString()}</strong></div>
                                <button type="submit" className="btn btn-outline-primary py-2 rounded-2 ms-auto" style={{ width: '140px' }}>
                                    Wycofaj
                                </button>
                            </div>
                        </form>
                    ) : (
                            <div className="d-flex flex-row flex-wrap">
                                <div className='me-2 align-self-end'>Wycofano: </div>
                                <div className='me-2 align-self-end'><strong>{new Date(consentDetails.Revoked).toLocaleString()}</strong></div>
                                <button type="submit" className="btn btn-primary py-2 rounded-2 ms-auto" style={{ width: '140px' }} onClick={handleAccept(consentDetails.Type)}>
                                    Zaakceptuj
                                </button>
                            </div>
                    )
                }
            </div>
        );
    }
}

export default Consent;