import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { ApiHost } from '../utils/ApiConfig';

const getStep = async (applicationId, accessToken) => {
    try {
        console.log("Pobieranie danych bieżącego kroku procesu dla wniosku [" + applicationId + "]");
        const response = await axios.post(ApiHost + `/process/get_current_step`, {
            applicationid: applicationId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let content = response.data;
            return content;
        }

    } catch (error) {
        console.error("Błąd podczas pobierania danych bieżącego kroku procesu dla wniosku: ", error);
    }
};

const commitStep = async (applicationId, accessToken) => {
    try {
        console.log("Potwierdzanie wykonania bieżącego kroku procesu dla wniosku [" + applicationId + "]");
        const response = await axios.post(ApiHost + `/process/commit_step`, {
            applicationid: applicationId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let content = response.data;
            return content;
        }
    } catch (error) {
        console.error("Błąd podczas potwierdzania wykonania bieżącego kroku procesu dla wniosku: ", error);
    }
};

const addInputToStep = async (applicationId, inputValue, accessToken) => {
    try {
        console.log("Dodawanie danych do bieżącego kroku procesu dla wniosku [" + applicationId + "]");
        const response = await axios.post(ApiHost + `/process/add_input_to_step`, {
            applicationid: applicationId,
            inputvalue: inputValue
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let content = response.data;
            return content;
        }
    } catch (error) {
        console.error("Błąd podczas dodawania danych do bieżącego kroku procesu dla wniosku: ", error);
    }
};

const start = async (applicationId, accessToken) => {
    try {
        console.log("Uruchamianie procesu dla wniosku [" + applicationId + "]");
        const response = await axios.post(ApiHost + `/process/start`, {
            applicationid: applicationId,
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let content = response.data;
            return content;
        }
    } catch (error) {
        console.error("Błąd podczas uruchamiania procesu dla wniosku: ", error);
    }
};

export {
    addInputToStep,
    commitStep,
    getStep,
    start
};