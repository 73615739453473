import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import { getApplicationContent, getApplicationMetadata } from '../../services/Application';
import LoadingSpinner from './LoadingSpinner';
import FileUploadComponent from './FileUploadComponent';
import CardHeader from './CardHeader';

const ApplicationSignature = ({ communicationHandler, registerCallback }) => {
    const { accessToken, checkPermission, userId } = useAuth();
    let { step, id } = useParams();
    let navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await getApplicationContent(id, accessToken);
            } catch (error) {
                console.error('Failed to fetch application content:', error);
            }
        };

        fetchData();
        setLoading(false);
    }, []);

    return (
        <div className='pb-4'>
            <CardHeader text="Podpis wniosku" onlyText="true" />
            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-column'>

                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <div>
                            <div className='mb-1'>
                                Pobierz wniosek do podpisania, przekaż go do podpisu uprawnionym osobom, a następnie załaduj ponownie do systemu.
                            </div>

                            <div className='pb-2 mb-2 border-bottom'>
                                <button type="submit" className="btn btn-primary w7 h2 rounded-2 ms-auto">
                                    Pobierz wniosek
                                </button>
                            </div>

                            <div className='mb-1'>
                                Tutaj dodaj podpisany wniosek.
                            </div>

                            <div>
                                <FileUploadComponent />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ApplicationSignature;