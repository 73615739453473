import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useParams } from 'react-router-dom';
import CommunicationMessage from './CommunicationMessage';
import { useAuth } from '../../context/AuthContext';
import { validateLettersDigitsSpecChars } from '../../utils/Validators';
import { updateMessages } from '../../services/Application';

function CommunicationPanel({ isVisible, data, communicationCallbacks }) {

    const [conversationData, setConversationData] = useState();
    const [messages, setMessages] = useState([]);
    const [messageText, setMessageText] = useState("");
    const [solved, setSolved] = useState(false);

    const { userName, checkPermission } = useAuth();

    let { step, id } = useParams();

    function getFormattedDate() {
        let date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Miesiące są liczone od 0
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    // TODO: uzupełnianie authorRole
    const createMessage = () => {
        let message = {
            author: userName,
            authorRole: (checkPermission("Obsługa wniosków") ? "operator" : "wnioskodawca"),
            createDate: getFormattedDate(),
            readDate: "",
            text: messageText
        };

        return message;
    };

    const handleMessageInput = (e) => {
        const value = e.target.value;

        if (validateLettersDigitsSpecChars(value, 3000, false))
            setMessageText(value);
    };

    const handleSend = async () => {
        const newMessage = createMessage();
        await setMessages(prevMessages => [...prevMessages, newMessage]);
        setMessageText("");
        //console.log(JSON.stringify([...messages, newMessage]));
        let newData = data;
        newData.Conversation = [...messages, newMessage]
        communicationCallbacks[conversationData.id]("open");
        updateMessages(id, step, newData);
    };

    const handleMarkAsSolved = (e) => {
        setSolved(e.target.checked);

        let newData = data;
        if (e.target.checked) {
            newData.status = "solved";
        }
        else {
            newData.status = (newData.Conversation.length > 0) ? "open" : "none";
        }
        communicationCallbacks[conversationData.id](newData.status);
        updateMessages(id, step, newData);
    };

    useEffect(() => {
        if (data) {
            setConversationData(data);
            setMessages(data.Conversation || []);
            setSolved(data.status === "solved");
        }
    }, [data]);

    if (!isVisible)
        return null;

    if (isVisible)
        return (
            <div className='d-flex flex-column pe-1' style={{ width: "300px" }}>
                <h3>WIADOMOŚCI</h3>

                {conversationData ? (
                    <div className='p-0'>
                        <div className=' d-flex flex-row ps-auto mb-1 w-100'>
                            {conversationData.locationName && (
                                <div className='ms-0 me-1 small'>{conversationData.locationName}</div>
                            )}
                            <div className='ms-auto me-0 very-small w1'>{conversationData.id}</div>
                        </div>

                        {messages && (messages.length > 0) ? (
                            <div className='w-90'>
                                {messages.map((message, index) => (
                                    <CommunicationMessage
                                        author={message.author}
                                        authorRole={message.authorRole}
                                        createDate={message.createDate}
                                        readDate={message.readDate}
                                        text={message.text}
                                        key={index}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div>
                                Brak wiadomości
                            </div>
                        )
                        }
                    </div>

                ) : (
                    <div>
                        <div>Kliknij ikonę komentarza</div>
                        <div className='rounded-2 border p-0 d-flex justify-content-center align-items-center ms-1' style={{ width: '30px', height: '30px', borderWidth: '0px', backgroundColor: "red", color: "white", fontSize: "18px" }}>
                            <i className='bi-envelope'></i>
                        </div>
                        w formularzu, aby otworzyć konwersację.
                    </div>
                )}

                {conversationData && !solved && (
                    <div className='d-flex flex-column mb-1 pb-1 border-bottom'>
                        <textarea
                            className='form-control mb-1 h6'
                            style={{ backgroundColor: "white" }}
                            placeholder='Wprowadź treść wiadomości'
                            value={messageText}
                            onChange={handleMessageInput}
                        />

                        {messageText.length > 0 ? (
                            <button type="submit" className="btn btn-primary w5 h2 rounded-2 ms-auto" onClick={() => handleSend()}>
                                Wyślij
                            </button>
                        ) : (
                            <button type="submit" className="btn btn-primary w5 h2 rounded-2 ms-auto" disabled>
                                Wyślij
                            </button>
                        )}
                    </div>
                )}

                {(conversationData && checkPermission("Obsługa wniosków")) && (
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="mark-as-solved" checked={solved} onChange={handleMarkAsSolved} />
                        <label className="form-check-label" htmlFor="mark-as-solved">
                            Oznacz jako rozwiązane
                        </label>
                    </div>
                )}
            </div>
        );
}

export default CommunicationPanel;
