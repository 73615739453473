import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import CommunicationPanel from './CommunicationPanel';
import OperationsPanel from './OperationsPanel';
import ProcessPanel from './ProcessPanel';

function OperatorsPanel({ isVisible, conversationId, data, trigger, communicationCallbacks, showProcess = false, modeChangeHandler, initialDisplayMode }) {

    const [communicationPanelVisible, setCommunicationPanelVisible] = useState(false);
    const [operationsPanelVisible, setOperationsPanelVisible] = useState(false);
    const [processPanelVisible, setProcessPanelVisible] = useState(false);
    const [displayMode, setDisplayMode] = useState(initialDisplayMode);

    const showCommunicationPanel = () => {
        hideAllPanels();
        setCommunicationPanelVisible(true);
    }

    const showOperationsPanel = () => {
        hideAllPanels();
        setOperationsPanelVisible(true);
    }

    const showProcessPanel = () => {
        hideAllPanels();
        setProcessPanelVisible(true);
    }

    const hideAllPanels = () => {
        setCommunicationPanelVisible(false);
        setOperationsPanelVisible(false);
        setProcessPanelVisible(false);
    }

    const handleDisplayMode = (mode) => {
        setDisplayMode(mode);
        modeChangeHandler(mode);
    }

    useEffect(() => {
        if (conversationId) {
            showCommunicationPanel();
            console.log("uE - CP Reload")
        }
        else if (showProcess) {
            showProcessPanel();
        }
    }, [trigger, conversationId]);

    if (!isVisible)
        return null;

    return (
        <div className='d-flex flex-row'>
            {(communicationPanelVisible || operationsPanelVisible || processPanelVisible) ? (
                <div className='ms-0 d-flex flex-row w15'>
                    <CommunicationPanel isVisible={communicationPanelVisible} data={data} communicationCallbacks={communicationCallbacks} />
                    <OperationsPanel isVisible={operationsPanelVisible} />
                    <ProcessPanel isVisible={processPanelVisible} />

                    <div className='d-flex flex-column p-0 mt-2 rounded-start-3 shadow'>
                        <div className={'sidepanel-nav ' + (communicationPanelVisible && 'sidepanel-nav-selected')} onClick={communicationPanelVisible ? hideAllPanels : showCommunicationPanel} title="Otwórz panel komunikacji">
                            <i className='bi-envelope' style={{ fontSize: "30px" }}></i>
                        </div>

                        <div className={'sidepanel-nav ' + (operationsPanelVisible && 'sidepanel-nav-selected')} onClick={operationsPanelVisible ? hideAllPanels : showOperationsPanel} selected="true" title="Otwórz panel właściwości">
                            <i className='bi-clipboard2-check' style={{ fontSize: "30px" }}></i>
                        </div>

                        <div className={'sidepanel-nav ' + (processPanelVisible && 'sidepanel-nav-selected')} onClick={processPanelVisible ? hideAllPanels : showProcessPanel} title="Otwórz panel zarządzania procesem">
                            <i className='bi-list-check' style={{ fontSize: "30px" }}></i>
                        </div>

                        <div className='my-1 border-bottom border-black' />

                        <div className={'sidepanel-nav ' + ((displayMode === 0) && 'sidepanel-nav-selected')} onClick={() => handleDisplayMode(0)}>
                            <i className='bi-input-cursor-text' style={{ fontSize: "30px" }}></i>
                        </div>
                        
                        <div className={'sidepanel-nav ' + ((displayMode === 1) && 'sidepanel-nav-selected')} onClick={() => handleDisplayMode(1)}>
                            <i className='bi-code-square' style={{ fontSize: "30px" }}></i>
                        </div>
                        
                        <div className={'sidepanel-nav ' + ((displayMode === 2) && 'sidepanel-nav-selected')} onClick={() => handleDisplayMode(2)}>
                            <i className='bi-cash-coin' style={{ fontSize: "24px" }}></i>
                        </div>
                        
                        <div className={'sidepanel-nav ' + ((displayMode === 3) && 'sidepanel-nav-selected')} onClick={() => handleDisplayMode(3)}>
                            <i className='bi-vector-pen' style={{ fontSize: "30px" }}></i>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='d-flex flex-column p-0 mt-2 rounded-start-3 shadow'>
                    <div className='sidepanel-nav' onClick={showCommunicationPanel}>
                        <i className='bi-envelope' style={{ fontSize: "30px" }}></i>
                    </div>

                    <div className='sidepanel-nav' onClick={showOperationsPanel}>
                        <i className='bi-clipboard2-check' style={{ fontSize: "30px" }}></i>
                    </div>

                    <div className='sidepanel-nav' onClick={showProcessPanel}>
                        <i className='bi-list-check' style={{ fontSize: "30px" }}></i>
                    </div>
                </div>
            )}

        </div>
    );
}

export default OperatorsPanel;
