import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { ApiHost } from '../../utils/ApiConfig';

const DataTableApp = ({ data, itemsPerPage, columnHeaders }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState('Firma');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortedData, setSortedData] = useState([]);
  let navigate = useNavigate();
  const { accessToken } = useAuth();
  const { userId } = useAuth();

  const unlinkBusiness = async (businessId) => {
    //e.preventDefault();
    try {
        console.log("Usuwanie powiązania użytkownika z firmą " + businessId);
        const response = await axios.post(ApiHost + `/business/unlink`, {
            user_id: userId,
            business_id: businessId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            console.log("Usunięto powiązanie użytkownika [" + userId + "] z firmą");
        }

    } catch (error) {
        console.error("Błąd usuwania powiązania z firmą: ", error);
    }

  }

  function editApplication(businessId){
    navigate('/application/edit/1/' + businessId, { replace: true });
  }

  // Efekt sortowania danych przy zmianie sortColumn lub sortOrder
  useEffect(() => {
    if (sortColumn) {
      const sorted = [...data].sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (a[sortColumn] > b[sortColumn]) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      });
      setSortedData(sorted);
    } else {
      setSortedData(data);
    }
  }, [sortColumn, sortOrder, data]);

  // Funkcja do zmiany bieżącej strony
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Funkcja do sortowania danych
  const handleSort = (column) => {
    if (sortColumn === column) {
      // Odwrócenie kolejności sortowania
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Ustawienie nowej kolumny do sortowania
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  // Obliczanie numeru pierwszego i ostatniego wiersza na bieżącej stronie
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            {/* Renderowanie nagłówków kolumn z obsługą sortowania */}
            {columnHeaders.map((header, index) => (
              <th key={index} onClick={() => handleSort(header)} style={{ cursor: 'pointer' }}>
                {header}
                {sortColumn === header && (
                  sortOrder === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* Renderowanie wierszy danych */}
          {currentItems.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {/* Renderowanie komórek w kolumnach */}
              {Object.keys(row).map((key, columnIndex) => (
                <td key={columnIndex}>
                {columnHeaders[columnIndex] === "Action" ? (
                  <div className='d-inline-block'>
                    <button className="btn btn-outline-primary" onClick={() => editApplication(row[key])}>Podgląd</button>
                    <button className="btn btn-outline-primary ms-1" onClick={() => unlinkBusiness(row[key])}>Usuń</button>
                  </div>
                  ) : (
                    columnHeaders[columnIndex] === "Data utworzenia" ? (
                  <div>
                    {row[key].split(' ')[0]}<br/>{row[key].split(' ')[1]}
                  </div>
                  ) : (
                    // Jeśli nie, renderuj normalne dane
                    row[key]
                  )
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {/* Renderowanie paginacji */}
      <nav>
        <ul className="pagination">
          {/* Tworzenie przycisków do zmiany strony */}
          {Array.from({ length: Math.ceil(sortedData.length / itemsPerPage) }, (_, i) => i + 1).map(pageNumber => (
            <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
              <button onClick={() => paginate(pageNumber)} className="page-link">{pageNumber}</button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default DataTableApp;
