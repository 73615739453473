import React from 'react';
import { Tooltip } from 'bootstrap';

function HelpTooltip({title}) {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })
  
    return (
        // <div className='bg-secondary rounded-circle border p-0 d-flex justify-content-center align-items-center ms-1' style={{ width: '20px', height: '20px', borderWidth: '0px', color: 'white', cursor: 'help' }} data-bs-toggle="tooltip" data-bs-placement="auto" data-bs-html="true" title={title}>
        <div className='bg-secondary rounded-circle border p-0 d-flex justify-content-center align-items-center ms-05 tooltip' style={{ width: '1.5rem', height: '1.5rem', borderWidth: '0px', color: 'white', cursor: 'help' }}>
            ?
            <span className='tooltiptext' dangerouslySetInnerHTML={{__html: title}} />
        </div>
    );
}

export default HelpTooltip;