import React, { useState, useEffect } from 'react';
import Sidebar from '../components/layout/Sidebar'
import BusinessAdd from '../components/common/BusinessAdd';

function AddBusiness() {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);

    const handleResize = () => {
        if (window.innerWidth >= 992) {
            setIsSidebarVisible(true);
        } else {
            setIsSidebarVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        //handleResize(); // Wywołanie przy montowaniu komponentu

        return () => window.removeEventListener('resize', handleResize); // Oczyszczenie przy demontowaniu
    }, []);

    return (
        <main className="d-flex flex-row" style={{ height: '100vh' }}>
            <Sidebar />
            <div className="content">
                <h1>Moje firmy</h1>
                <BusinessAdd />
            </div>
        </main>
    );
}

export default AddBusiness;
