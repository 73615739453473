import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { ApiHost } from '../utils/ApiConfig';

const getStatuses = async (accessToken) => {
    try {
        console.log("Pobieranie słownika statusów");
        const response = await axios.get(ApiHost + `/dictionaries/statuses`, {
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let statuses = response.data;
            return statuses;
        }
        return "Nieznany";
    } catch (error) {
        console.error("Błąd podczas pobierania listy statusów: ", error);
    }
};

const getChambers = async (accessToken) => {
    try {
        console.log("Pobieranie słownika izb.");
        const response = await axios.get(ApiHost + `/dictionaries/chambers`, {
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let chambers = response.data;
            return chambers;
        }
        return "Nieznany";
    } catch (error) {
        console.error("Błąd podczas pobierania listy izb: ", error);
    }
};

const getRoles = async (accessToken) => {
    try {
        console.log("Pobieranie słownika ról.");
        const response = await axios.get(ApiHost + `/dictionaries/roles`, {
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let roles = response.data;
            return roles;
        }
        return "Nieznany";
    } catch (error) {
        console.error("Błąd podczas pobierania listy ról: ", error);
    }
};

const getUsers = async (chamberId, accessToken) => {
    try {
        console.log("Pobieranie listy użytkowników. [" + chamberId + "]");
        const response = await axios.post(ApiHost + `/dictionaries/users`, {
            chamberid: chamberId
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        if (response.status === 200) {
            let result = response.data;
            console.log("getUsers Result: " + result);
            return result;
        }
        return "Nieznany";
    } catch (error) {
        console.error("Błąd podczas pobierania listy użytkowników: ", error);
    }
};

export {
    getChambers,
    getRoles,
    getStatuses,
    getUsers
};