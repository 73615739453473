import React, { useState } from 'react';
import LoginForm from '../components/common/LoginForm';
import UserRegistration from '../components/common/UserRegistration';
import UserRegistrationResult from '../components/common/UserRegistrationResult';
import UserActivation from '../components/common/UserActivation';

function LoginPage({mode = "login"}){

return (

  <div className="d-flex align-items-center bg-secondary relative" style={{ overflowX: 'hidden', minHeight: '100vh' }}>
    <img src="/assets/images/globe-5LTxm7b1.webp" className="img-cover d-none d-md-block" alt="..."></img>
    <div className="container relative">
      {mode === "login" && (
        <LoginForm />
      )}
      {mode === "register" && (
        <UserRegistration />
      )}
      {(mode === "register-success" || mode === "register-failure") && (
        <UserRegistrationResult success={mode === "register-success"} />
      )}
      {mode === "activate" && (
        <UserActivation />
      )}
    </div>
  </div>
  );
}

export default LoginPage;
