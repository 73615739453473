/**
 * Represents the page for processing a specific application.
 * @module ProcessApplication
 * @returns {JSX.Element} The JSX element representing the process application page.
 */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ApplicationAdd from '../components/common/ApplicationAdd';
import ApplicationStep2 from '../components/common/ApplicationStep2';
import ApplicationStep3 from '../components/common/ApplicationStep3';
import ApplicationStep4 from '../components/common/ApplicationStep4';
import ApplicationStep5 from '../components/common/ApplicationStep5';
import ApplicationMetadata from '../components/common/ApplicationMetadata';
import ApplicationPayment from '../components/common/ApplicationPayment';
import ApplicationSignature from '../components/common/ApplicationSignature';
import OperatorsPanel from '../components/common/OperatorsPanel';
import Stepper from '../components/common/Stepper';
import Sidebar from '../components/layout/Sidebar';
import { getApplicationMetadata } from '../services/Application';

/**
 * Represents the ProcessApplication component.
 * This component handles the process of an application, including its steps, status, and display mode.
 * @returns {JSX.Element} The ProcessApplication component.
 */
function ProcessApplication() {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [conversationId, setConversationId] = useState();
    const [conversationData, setConversationData] = useState();
    const [status, setStatus] = useState(null);
    const [trigger, setTrigger] = useState(0);
    const [callbackRegister, setCallbackRegister] = useState({});
    const [displayMode, setDisplayMode] = useState(0); // 0 = Application, 1 = Metadata, 2 = Payment, 3 = Signature
    const [warnings, setWarnings] = useState([0, 0, 0, 0, 0]);
    const [messages, setMessages] = useState([0, 0, 0, 0, 0]);
    const applicationStepTitles = [
        'WNIOSKODAWCA',
        'EKSPORTOWANY PRODUKT / TOWAR',
        'PROCES PRODUKCJI / ODBIORCA TOWARU',
        'ZAŁĄCZNIKI',
        'PODSUMOWANIE / OŚWIADCZENIA'];
    /**
     * Array of metadata step titles.
     * @type {string[]}
     */
    const metadataStepTitles = [
        "WYPEŁNIANY",
        "ROZPATRYWANY",
        "OPŁATA I PODPIS",
        "W AKCEPTACJI",
        "ŚWIADECTWO WYSTAWIONE"];
    const metadataStepTitlesCancelled = [
        "WYPEŁNIANY",
        "ROZPATRYWANY",
        "OPŁATA I PODPIS",
        "W AKCEPTACJI",
        "SPRAWA ANULOWANA"];
    const statusToModeMap = {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 2,
        6: 2,
        7: 1,
        8: 1,
        9: 1,
        10: 3,
        11: 2,
        12: 3,
        13: 1,
        14: 1,
        15: 1,
        16: 1,
        17: 1,
        18: 1
    };
    const statusToActiveStepMap = {
        1: 1,
        2: 1,
        3: 2,
        4: 1,
        5: 3,
        6: 3,
        7: 2,
        8: 5,
        9: 3,
        10: 3,
        11: 3,
        12: 3,
        13: 4,
        14: 4,
        15: 5,
        16: 5,
        17: 5,
        18: 5
    };
    let { step, id } = useParams();

    const handleResize = () => {
        if (window.innerWidth >= 992) {
            setIsSidebarVisible(true);
        } else {
            setIsSidebarVisible(false);
        }
    };

    const handleDisplayModeChange = (mode) => {
        setDisplayMode(mode);
    }

    const registerCallback = (callbackId, callback) => {
        setCallbackRegister(prevState => ({
            ...prevState,
            [callbackId]: callback
        }));
        console.log("rC [" + callbackId + "]\n" + JSON.stringify({ ...callbackRegister, [callbackId]: callback }));
    };

    const setAllCommunicationSpotsInactive = () => {
        Object.values(callbackRegister).forEach(callback => {
            callback("inactive");
        });
    };

    const openCommunicationPanel = (conversationId, trigger, data) => {
        setAllCommunicationSpotsInactive();
        setConversationId(conversationId);
        setConversationData(data);
        setTrigger(trigger);
    }

    const setWarningCount = (index, count) => {
        console.log("Warning count [" + index + "]: " + count);
        const newWarnings = [...warnings]; // Tworzenie nowej tablicy
        newWarnings[index] = count;
        setWarnings(newWarnings);
    }

    useEffect(() => {
        console.log("trigger changed [" + trigger + "]");
    }, [trigger])

    useEffect(() => {
        console.log("displayMode changed [" + displayMode + "]");
    }, [displayMode])

    useEffect(() => {
        console.log("warnings changed [" + warnings[0] + "]");
    }, [warnings])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const metadata = await getApplicationMetadata(id);
                if (metadata) {
                    setStatus(metadata.status);
                    setDisplayMode(statusToModeMap[metadata.status]);
                    console.log("AA - uE, status: " + status);
                }
            } catch (error) {
                console.error('Failed to fetch application metadata:', error);
            }
        };

        fetchData();

        window.addEventListener('resize', handleResize);
        //handleResize(); // Wywołanie przy montowaniu komponentu

        return () => window.removeEventListener('resize', handleResize); // Oczyszczenie przy demontowaniu
    }, []);

    return (
        <main className="d-flex flex-row" style={{ height: '100vh' }}>
            <Sidebar />
            <div className='d-flex flex-column w-100'>
                <Stepper
                    activeStep={displayMode === 0 ? step : statusToActiveStepMap[status]}
                    warnings={warnings}
                    id={id}
                    titles={displayMode === 0 ? applicationStepTitles : (status === 8 ? metadataStepTitlesCancelled : metadataStepTitles)}
                    applicationMode={displayMode === 0}
                />
                <div className="d-flex flex-row content p-0 w-100">
                    {displayMode === 0 && (
                    <div>
                        {(step === '1') && <ApplicationAdd communicationHandler={openCommunicationPanel} registerCallback={registerCallback} warningsCallback={setWarningCount} />}
                        {(step === '2') && <ApplicationStep2 communicationHandler={openCommunicationPanel} registerCallback={registerCallback} />}
                        {(step === '3') && <ApplicationStep3 communicationHandler={openCommunicationPanel} registerCallback={registerCallback} />}
                        {(step === '4') && <ApplicationStep4 communicationHandler={openCommunicationPanel} registerCallback={registerCallback} />}
                        {(step === '5') && <ApplicationStep5 communicationHandler={openCommunicationPanel} registerCallback={registerCallback} />}
                    </div>
                    )}

                    {displayMode === 1 && (
                        <div className='ms-auto'>
                            <ApplicationMetadata />
                        </div>
                    )}

                    {displayMode === 2 && (
                        <div className='ms-auto'>
                            <ApplicationPayment />
                        </div>
                    )}

                    {displayMode === 3 && (
                        <div className='ms-auto'>
                            <ApplicationSignature />
                        </div>
                    )}

                    <div className='mt-4 ms-auto' style={{ position: "sticky", top: "2rem" }}>
                        {status != null && (
                            <OperatorsPanel
                                isVisible={true}
                                conversationId={conversationId}
                                data={conversationData}
                                trigger={trigger}
                                communicationCallbacks={callbackRegister}
                                showProcess={(status > 1)}
                                modeChangeHandler={handleDisplayModeChange}
                                initialDisplayMode={displayMode}
                            />
                        )}
                    </div>
                </div>

            </div>
        </main>
    );
}

export default ProcessApplication;
