import React, { useEffect, useState } from 'react';
import ProducerAddress from './ProducerAddress';
import HelpTooltip from './HelpTooltip';

const ShippingDetails = ({applicant, data, onDataChange}) => {
    const [shippingMethod, setShippingMethod] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleAddressChange = (event) => {
        //setAddress(event.target.value);
        data[event.target.name] = event.target.value;
        onDataChange(event);
    };


    const handleShippingMethodChange = (event) => {
        setShippingMethod(event.target.value);
        if (event.target.value === 'courier') {
            //setPhoneNumber(applicant.phoneNumber);
            data.phoneNumber = applicant.phoneNumber;
        }
        onDataChange(event);
    };

    const handlePhoneNumberChange = (event) => {
        if (/^[0-9+/ ]*$/.test(event.target.value)){
            setPhoneNumber(event.target.value);
            onDataChange(event);
        }
    };

    useEffect(() => {
        if (data) {
            setShippingMethod(data.shippingMethod);
            setPhoneNumber(data.phoneNumber);
            console.log(data.phoneNumber);
        }
    }, [data]);

    useEffect(() => {
        if (data.phoneNumber) {
            setPhoneNumber(data.phoneNumber);
        }
        else
            setPhoneNumber(applicant.phoneNumber);
    }, []);

    return (
        <div className='ps-2 d-flex flex-column'>
            <label className='mb-1' htmlFor="shippingMethod">Wybierz formę dostawy</label>
            <div className="form-check form-check-inline mb-1">
                <input className="form-check-input" type="radio" id="pickup" name="shippingMethod" value="pickup" checked={shippingMethod === 'pickup'} onChange={handleShippingMethodChange} />
                <label className="form-check-label" htmlFor="pickup">Odbiór osobisty</label>
            </div>
            <div className="form-check form-check-inline mb-1">
                <input className="form-check-input" type="radio" id="courier" name="shippingMethod" value="courier" checked={shippingMethod === 'courier'} onChange={handleShippingMethodChange} />
                <label className="form-check-label" htmlFor="courier"><div className='d-flex flex-row'>Kurier <HelpTooltip title="Opłata za dostawę wynosi 30 zł." /></div></label>
            </div>
            {shippingMethod === 'courier' && (
                <div className='mb-1'>
                    <ProducerAddress isProducer="true" shippingAddress="true" data={data} applicant={applicant} onDataChange={handleAddressChange} />
                    <div className='mt-1'>
                        <div className='d-flex flex-row'>
                        <label className="form-label">Numer telefonu dla kuriera *</label>
                        <HelpTooltip title="Podanie numeru jest niezbędne, aby możliwe było zrealizowanie usługi wysyłki." />
                        </div>
                        <input type="text" className="form-control my-0 rounded-3 w13" name='phoneNumber' maxLength="50" value={phoneNumber} onChange={handlePhoneNumberChange} placeholder="Numer telefonu" required={false} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShippingDetails;