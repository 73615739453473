import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import { createEmptyApplication, getApplicationContent, updateApplicationContent } from '../../services/Application';
import FileUploadComponent from './FileUploadComponent';
import HelpTooltip from './HelpTooltip';
import CertificatePreview from './CertificatePreview';
import InfoPopup from './InfoPopup';
import CommunicationSpot from './CommunicationSpot';
import ChamberSelector from './ChamberSelector';
import LoadingSpinner from './LoadingSpinner';
import { getApplicationMetadata } from '../../services/Application';
import { commitStep, start } from '../../services/Process';
import { clientCanEdit, operatorCanEdit } from './ReadOnly';
import ReadonlyOverlay from './ReadonlyOverlay';
import CardHeader from './CardHeader';
import ShippingDetails from './ShippingDetails';

const tooltips = ["Załączniki wprowadzone na poszczególnych krokach wniosku. Jeśli chcesz je edytować / dodawać, cofnij się do odpowiedniego kroku.",
    "Domyślnie, otrzymujesz od nas elektroniczne świadectwo, które możesz przesłać do dowolnej liczby podmiotów. W wyjątkowych sytuacjach, Twoi kontrahenci lub podmioty obsługujące transakcję (bank, agencja celna, urząd celny) mogą prosić o papierowe kopie świadectwa opatrzone naszą oryginalną pieczęcią. W takiej sytuacji, podaj ilość kopii. Opłata za każdą kopię wynosi 5 zł."];
const statementsDescr = ["Eksportowany towar spełnia kryteria pochodzenia zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) nr 952/2013 (Dziennik Urzędowy Unii Europejskiej L 269 z dnia 9.10.2013 r.) artykuły: 59 do 63. *",
    "Eksportowany towar jest tym samym, który został zakupiony i nie podlegał żadnym przeróbkom *",
    "Inne dokumenty określające pochodzenie towaru znajdują się w naszym posiadaniu i będą udostępnione do wglądu na żądanie Krajowej Izby Gospodarczej oraz będą przechowywane przez okres 5 lat. *",
    "Faktury na zakup surowców i inne dokumenty określające pochodzenie towaru znajdują się w posiadaniu firmy i będą udostępnione do wglądu na żądanie Krajowej Izby Gospodarczej oraz będą przechowywane przez okres 5 lat. *",
    "Uprzedzony/a i świadom/a odpowiedzialności karnej wynikającej z art. 272 i 273 Kodeksu Karnego za wyłudzenie poświadczenia nieprawdy i używanie uzyskanego w ten sposób dokumentu oświadczam, iż na dzień sporządzenia niniejszego wniosku dane w nim zawarte oraz danei informacje wynikające z załączonych do wniosku, dostarczonych do Krajowej Izby Gospodarczej dokumentów, są prawdziwe i aktualne. *",
    "Wywóz towaru następuje z terenu Polski *",
    "Nie ubiegałem się i nie ubiegam się o wydanie świadectwa pochodzenia w urzędzie celnym na partię towaru objętą niniejszym wnioskiem *"];

/**
 * Reprezentuje komponent ApplicationStep5.
 * 
 * @param {Object} props - Właściwości komponentu.
 * @param {Function} props.communicationHandler - Funkcja obsługująca komunikację.
 * @param {Function} props.registerCallback - Funkcja rejestracji zwrotnego wywołania.
 * @returns {JSX.Element} Komponent ApplicationStep5.
 */
const ApplicationStep5 = ({ communicationHandler, registerCallback }) => {
    const { accessToken, checkPermission, userId } = useAuth();
    let { step, id } = useParams();
    let navigate = useNavigate();

    const [application, setApplication] = useState(createEmptyApplication());
    const [step1, setStep1] = useState(null);
    const [step2, setStep2] = useState(null);
    const [step3, setStep3] = useState(null);
    const [step4, setStep4] = useState(null);
    const [step5, setStep5] = useState(null);
    const [status, setStatus] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [statements, setStatements] = useState([false, false, false, false, false, false, false]);
    const [selectAll, setSelectAll] = useState(false);
    const [remarks, setRemarks] = useState("");
    const [infoPopupTrigger, setInfoPopupTrigger] = useState(0);
    const [chamber, setChamber] = useState();
    const [chamberName, setChamberName] = useState();
    const [printYes, setPrintYes] = useState();
    const [additionalPrints, setAdditionalPrints] = useState();
    const [readOnly, setReadOnly] = useState(false);
    const [applicantData, setApplicantData] = useState({ producerName: "", producerCountry: "", producerStreet: "", producerBuilding: "", producerAppartment: "", producerPostCode: "", producerCity: "", phoneNumber: "" });
    const [shippingData, setShippingData] = useState({ producerName: "", producerCountry: "", producerStreet: "", producerBuilding: "", producerAppartment: "", producerPostCode: "", producerCity: "", phoneNumber: "", shippingMethod: "" });

    const handleSave = async () => {
        let app = application;
        app.Step_5.Data.statements = statements;
        app.Step_5.Data.remarks = remarks;
        app.Step_5.Data.chamber = chamber;
        try {
            if (printYes != null) {
                app.Step_5.Data.printYes = printYes;
                if (printYes === true) {
                    app.Step_5.Data.shippingMethod = shippingData.shippingMethod;
                    if (shippingData.shippingMethod === "courier") {
                        app.Step_5.Data.ShippingAddress = {
                            name: shippingData.producerName,
                            country: shippingData.producerCountry,
                            street: shippingData.producerStreet,
                            building: shippingData.producerBuilding,
                            appartment: shippingData.producerAppartment,
                            postCode: shippingData.producerPostCode,
                            city: shippingData.producerCity,
                            phoneNumber: shippingData.phoneNumber
                        };
                    }
                }
                app.Step_5.Data.additionalPrints = additionalPrints;
            }
        }
        catch (ex) {
            console.error('Failed to save shipping data:', ex);
        }
        console.log("AS5, hS, chamber: " + chamber);
        await updateApplicationContent(id, app, userId, accessToken);
        setInfoPopupTrigger(infoPopupTrigger + 1);
    };

    const handleForward = async (e) => {
        e.preventDefault();
        handleSave();
        //await updateApplicationContent();
        const started = await start(id, accessToken);
        if (started)
            commitStep(id, accessToken);
        navigate('/application/list', { replace: true });
    };

    const handleBack = async (e) => {
        e.preventDefault();
        navigate('/application/edit/' + (parseInt(step) - 1) + '/' + id, { replace: true });
    };

    const handleChamberChange = (e) => {
        setChamber(e.target.value);
    }

    const handleRemarksChange = (e) => {
        setRemarks(e.target.value);
    }

    const handleShippingDataChange = (e) => {
        const tempShippingData = { ...shippingData, [e.target.name]: e.target.value };
        setShippingData(tempShippingData);
    }

    const handleStatementChange = (index, checked) => {
        const updatedStatements = [...statements];
        updatedStatements[index] = checked;
        setStatements(updatedStatements);

        if (!checked) {
            setSelectAll(false);
        } else if (updatedStatements.every(statement => statement)) {
            setSelectAll(true);
        }
    };

    const handleSelectAllStatement = (e) => {
        const isChecked = e.target.checked;
        const updatedStatements = statements.map(() => isChecked);
        setStatements(updatedStatements);
        setSelectAll(isChecked);
    };

    const loadAttachments = async () => {
        try {
            const newAttachments = [];

            // Step 1
            if (step1.signedByAgent) {
                newAttachments.push(...step1.proxyFiles.filter(file => file.id));
                //newAttachments.push(step1.proxyFile);
            }
            if (step1.IE599File.id)
                newAttachments.push(step1.IE599File);

            // Step 2
            step2.Products.forEach(product => {
                if (product.boughtOutsideEU === "true") {
                    if (product.importClearanceFile)
                        newAttachments.push(product.importClearanceFile);
                }
            });

            // Step 3 - do uzupełnienia po rozszerzeniu o dodawanie pliku opisu procesu produkcji
            step3.Processes.forEach(process => {
                newAttachments.push(...process.processFiles.filter(file => file.id));
            });

            // Step 4
            newAttachments.push(...step4.exportInvoiceFiles.filter(file => file.id));
            newAttachments.push(...step4.goodsFiles.filter(file => file.id));
            newAttachments.push(...step4.otherGoodsFiles.filter(file => file.id));
            newAttachments.push(...step4.productFiles.filter(file => file.id));
            if (step4.paymentFile.id) newAttachments.push(step4.paymentFile);

            setAttachments(newAttachments);
        } catch (ex) {
            console.error('Failed to load attachments:', ex);
        }
    };

    const handleRadioDataChange = async (e) => {
        try {
            let app = application;
            if (e.target.id === "printYes") {
                setPrintYes(e.target.checked);
            }
            else {
                setPrintYes(!e.target.checked);
            }
            //await updateApplicationContent(id, app, userId, accessToken);
        }
        catch (ex) {
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getApplicationContent(id, accessToken);
                if (response.Step_5) {
                    setApplication(response);

                    if (response.Step_1) {
                        setStep1(response.Step_1.Data);
                        try {
                            setApplicantData({
                                producerName: response.Step_1.Data.applicantName,
                                producerCountry: "PL",
                                producerStreet: response.Step_1.Data.applicantStreet,
                                producerBuilding: response.Step_1.Data.applicantBuilding,
                                producerAppartment: response.Step_1.Data.applicantAppartment,
                                producerPostCode: response.Step_1.Data.applicantPostCode,
                                producerCity: response.Step_1.Data.applicantCity,
                                phoneNumber: response.Step_1.Data.phoneNumber
                            });
                        } catch (error) {
                            console.error('Failed to fetch applicant data:', error);
                        }
                    }

                    setStep2(response.Step_2.Data);
                    setStep3(response.Step_3.Data);
                    setStep4(response.Step_4.Data);
                    setStep5(response.Step_5.Data);
                    console.log(response.Step_5.Data);
                    if (response.Step_5.Data.statements)
                        setStatements(response.Step_5.Data.statements);
                    if (response.Step_5.Data.remarks)
                        setRemarks(response.Step_5.Data.remarks);
                    if (response.Step_5.Data.chamber)
                        setChamber(response.Step_5.Data.chamber);
                    else
                        setChamber("KIG");
                    if (response.Step_5.Data.printYes) {
                        setPrintYes(response.Step_5.Data.printYes);
                        console.log("AS5, uE, printYes: " + response.Step_5.Data.printYes);
                        if (response.Step_5.Data.printYes === true) {
                            let tempShippingData = shippingData;
                            if (response.Step_5.Data.additionalPrints)
                                setAdditionalPrints(response.Step_5.Data.additionalPrints);
                            else    
                                setAdditionalPrints(0);
                            tempShippingData.shippingMethod = response.Step_5.Data.shippingMethod;
                            if (response.Step_5.Data.shippingMethod === "courier") {
                                try {
                                    tempShippingData.producerName = response.Step_5.Data.ShippingAddress.name;
                                    tempShippingData.producerCountry = response.Step_5.Data.ShippingAddress.country;
                                    tempShippingData.producerStreet = response.Step_5.Data.ShippingAddress.street;
                                    tempShippingData.producerBuilding = response.Step_5.Data.ShippingAddress.building;
                                    tempShippingData.producerAppartment = response.Step_5.Data.ShippingAddress.appartment;
                                    tempShippingData.producerPostCode = response.Step_5.Data.ShippingAddress.postCode;
                                    tempShippingData.producerCity = response.Step_5.Data.ShippingAddress.city;
                                    tempShippingData.phoneNumber = response.Step_5.Data.ShippingAddress.phoneNumber;
                                    if (tempShippingData.phoneNumber === null || tempShippingData.phoneNumber === "")
                                        tempShippingData.phoneNumber = response.Step_1.Data.phoneNumber;
                                } catch (error) {
                                    console.error('Failed to fetch shipping data:', error);
                                }

                                setShippingData(tempShippingData);
                            }
                        }
                    }
                }

                const metadata = await getApplicationMetadata(id, accessToken);
                if (metadata) {
                    setStatus(metadata.status);
                    setChamberName(metadata.Chamber.name);
                }

                if (checkPermission("Obsługa wniosków")) {
                    if (!operatorCanEdit(metadata.status))
                        setReadOnly(true);
                }
                else {
                    if (!clientCanEdit(metadata.status))
                        setReadOnly(true);
                }
                setDataLoaded(true);
            } catch (error) {
                console.error('Failed to fetch application content:', error);
            }
        };

        fetchData();

    }, []);

    useEffect(() => {
        if (dataLoaded) {
            loadAttachments();
        }
    }, [dataLoaded, step1, step2, step3, step4, step5]);

    useEffect(() => {
        console.log("chamber: " + chamber);
    }, [chamber])

    return (
        <div>
            <CardHeader text="Podsumowanie" commentId="5-1" commentVisible={status !== 1} communicationHandler={communicationHandler} registerCallback={registerCallback} />

            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-column w47'>
                    <div className='mb-1'>
                        Podsumowanie zawiera wprowadzone we wniosku dane w kolejności ułożenia na świadectwie. Sprawdź czy wszystkie pola są wypełnione zgodnie z Twoją intencją.<br />
                        Jeśli chcesz dokonać zmian, cofnij się do odpowiedniego kroku formularza.<br />
                        Jeśli operator zaakceptuje Twój wniosek, to świadectwo wypełnione na podstawie wprowadzonych danych będzie wyglądać następująco:
                    </div>

                    <div>
                        <CertificatePreview applicationId={id} />
                        {/* <ImageComponent fileId="883c7b86-1667-47b6-9374-f4e89b1d8139" /> */}
                    </div>
                </div>
            </div>

            <CardHeader text="Załączniki" commentId="5-2" commentVisible={status !== 1} communicationHandler={communicationHandler} registerCallback={registerCallback} />

            <div className='card container-fluid shadow ms-4 px-4 py-3 pb-1 w55'>
                <div className='d-flex flex-column w47'>

                    {attachments && (
                        attachments.map((file, index) => (
                            <div className='pb-2' key={"files_" + index}>
                                <FileUploadComponent fileId={file.id} fileName={file.name} fileDescription={file.description} fileType="productFiles" index={index} readOnly='true' />
                            </div>
                        )
                        ))}

                </div>
            </div>

            <CardHeader text="Oświadczenia" commentId="5-3" commentVisible={status !== 1} communicationHandler={communicationHandler} registerCallback={registerCallback} />

            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-column w47'>
                    <ReadonlyOverlay readonly={readOnly}>
                        <div>
                            <div>
                                <div className='d-flex flex-row pb-1'>
                                    <input className="form-check-input" type='checkbox' id={"statement_selectAll"} checked={selectAll} onChange={handleSelectAllStatement} />
                                    <label className='form-check-label' htmlFor={"statement_selectAll"}><strong>Zaznacz wszystkie</strong></label>
                                </div>

                                {statementsDescr.map((statement, index) => (
                                    <div className='d-flex flex-row pb-1' key={"statement_" + index}>
                                        <input className="form-check-input" type='checkbox' id={"statement_" + index} checked={statements[index]} onChange={(e) => handleStatementChange(index, e.target.checked)} />
                                        <label className='form-check-label' htmlFor={"statement_" + index}>{statement}</label>
                                    </div>
                                )
                                )}
                            </div>

                            <div>
                                <div className='pt-1'>
                                    {chamber || dataLoaded ? (
                                        (status === 1) ? (
                                            <ChamberSelector name="chamber" data={chamber} label="Wniosek będzie obsługiwać" onDataChange={handleChamberChange} required="false" />
                                        ) : (
                                            <div className="form-group">
                                                <label className="form-label">Wniosek będzie obsługiwać</label>
                                                <input className="form-control" type="text" value={chamberName} readOnly />
                                            </div>
                                        )
                                    ) : (
                                        <LoadingSpinner />
                                    )}
                                </div>
                            </div>
                        </div>
                    </ReadonlyOverlay>

                </div>
            </div>

            <CardHeader text="Wydruk i dostarczenie świadectwa" commentId="5-4" commentLocation="Wydruk i dostarczenie" commentVisible={status !== 1} communicationHandler={communicationHandler} registerCallback={registerCallback} />

            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-column w47'>
                    <ReadonlyOverlay readonly={readOnly}>
                        <div className='mb-1 d-flex flex-row mb-1'>
                            <label className="form-label"><strong>Czy potrzebujesz wydruk świadectwa? *</strong></label>
                            <HelpTooltip title="Otrzymasz świadectwo w formie elektronicznej (plik PDF podpisany pieczęcią elektroniczą Krajowej Izby Gospodarczej), ale możesz także zamówić wydruk na specjalnym druku, tzw. giloszu. Opłata za wydruk wynosi 35 zł brutto." />
                        </div>

                        <div className='d-flex flex-column'>
                            <div className="form-check form-check-inline mb-1">
                                <input className="form-check-input" type="radio" name="printYes" id="printYes" checked={printYes} onChange={handleRadioDataChange} />
                                <label className="form-check-label" htmlFor="printYes">TAK</label>
                            </div>
                            {(printYes && (shippingData && applicantData)) && (
                                <div className='d-flex flex-column'>
                                    <div className='d-flex flex-column w17 ms-2 mb-1'>
                                        <div className='d-flex flex-row w-100'>
                                            <label className="form-label">Liczba dodatkowych kopii świadectwa *</label>
                                            <HelpTooltip title={tooltips[1]} />
                                        </div>
                                        <select
                                            className="form-control my-0 h2 rounded-3"
                                            name="chamberSelect"
                                            value={additionalPrints}
                                            onChange={(e) => setAdditionalPrints(e.target.value)} 
                                            required="true"
                                        >
                                            {Array.from({ length: 21 }, (_, index) => (
                                                <option key={index} value={index}>
                                                    {index}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <ShippingDetails
                                        applicant={applicantData}
                                        data={shippingData}
                                        onDataChange={handleShippingDataChange}
                                    />
                                </div>

                            )}
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="printNo" id="printNo" checked={printYes != null && !printYes} onChange={handleRadioDataChange} />
                                <label className="form-check-label" htmlFor="printNo">NIE</label>
                            </div>
                        </div>
                    </ReadonlyOverlay>
                </div>
            </div>

            <CardHeader text="Uwagi" commentId="5-5" commentVisible={status !== 1} communicationHandler={communicationHandler} registerCallback={registerCallback} />

            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-column w47'>
                    <div>

                        <ReadonlyOverlay readonly={readOnly}>
                            <div>
                                <label className="form-label">Uwagi dla operatora wniosku</label>
                                <textarea className="form-control my-0 rounded-3" style={{ height: "120px" }} maxLength="280" name="remarks" value={remarks} onChange={handleRemarksChange} placeholder='Uwagi dotyczące wniosku' />
                            </div>
                        </ReadonlyOverlay>
                    </div>
                </div>
            </div>

            <form onSubmit={handleForward}>
                <div className="d-flex flex-row ms-4 mt-3 pb-4 w55">
                    <button className="btn btn-outline-primary rounded-2 me-auto w11 h2" onClick={handleBack}>
                        Wstecz
                    </button>

                    {!readOnly && (
                        <button
                            type="button"
                            className="btn btn-outline-primary rounded-2 w11 h2"
                            onClick={handleSave}
                        >
                            Zapisz wersję roboczą
                        </button>
                    )}

                    {status != null ? (
                        (status === 1 && !readOnly) && (<button type="submit" className="btn btn-primary rounded-2 ms-1 w11 h2">
                            Zapisz i wyślij wniosek
                        </button>)
                    ) : (
                        <div className="btn btn-primary rounded-2 ms-1 w11 h2">
                            <LoadingSpinner />
                        </div>
                    )}
                </div>
            </form>

            <InfoPopup title={"Zapisano wersję roboczą wniosku."} trigger={infoPopupTrigger} />
        </div>
    );
};

export default ApplicationStep5;
