import React from 'react';
import TFLogin from './TFLogin';

function LoginForm() {
  return (
    <div className="row justify-content-center justify-content-md-start">
    <div className="col-12 col-md-8 col-lg-6 col-xl-5 my-2 w-40">
      <span className="d-flex flex-column align-items-center mb-2">
        <img src="/assets/images/kig-e7Ti-vgY.png" className="mx-auto" alt="..." />
        <span className="text-white" style={{ fontSize: '0.75rem'}}>KRAJOWA IZBA GOSPODARCZA</span>
      </span>
      <div className="py-1 px-4 px-xl-5 bg-white rounded-4">
        <h1 className="display-4 text-center mb-1">Logowanie</h1>
        <p className="text-muted text-center mb-2">Elektroniczne świadectwo pochodzenia</p>
        <TFLogin />
      </div>
      </div>
      </div>
  );
}

export default LoginForm;