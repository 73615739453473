import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateEmail, validateLetters, validateLettersDigitsSpecChars } from '../../utils/Validators';
import { addUser } from '../../services/User';

function UserRegistration() {
  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [valid, setValid] = useState(false);

  const handleInput = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "firstName":
        if (validateLetters(value, 40, false))
          setFirstName(value);
        break;
      case "lastName":
        if (validateLetters(value, 40, false))
          setLastName(value);
        break;
      case "email":
        if (validateLettersDigitsSpecChars(value, 250, false))
          setEmail(value);
        break;
      default:
    }
  }

  const handleUserRegister = async () => {
    let result = false;
    result = await addUser(email, firstName, lastName, true);
    if (result){
      navigate("/Register/Success");
    }
    else
      navigate("/Register/Failure");
  }

  const validate = () => {
    setValid(validateLetters(firstName, 40, true) && validateLetters(lastName, 40, true) && validateEmail(email, true));
  }

  useEffect(() => {
    validate();
  }, [firstName, lastName, email]);

  return (
    <div className="row justify-content-center justify-content-md-start">
      <div className="col-12 col-md-8 col-lg-6 col-xl-5 my-2 w-40">
        <span className="d-flex flex-column align-items-center mb-2">
          <img src="/assets/images/kig-e7Ti-vgY.png" className="mx-auto" alt="..." />
          <span className="text-white" style={{ fontSize: '0.75rem' }}>KRAJOWA IZBA GOSPODARCZA</span>
        </span>
        <div className="py-1 px-4 px-xl-5 bg-white rounded-4 w-100">
          <h1 className="display-4 text-center mb-1">Rejestracja użytkownika</h1>
          <p className="text-muted text-center mb-2">Elektroniczne świadectwo pochodzenia</p>
          <div className='d-flex flex-column w-100'>
            <div className='mb-1'>
              <label className="form-label">Adres email *</label>
              <input
                type="text"
                className="form-control my-0 h2 rounded-3"
                name='email'
                value={email}
                onChange={handleInput}
                maxLength="250"
                placeholder="Adres email, np. jan.kowalski@email.com.pl (wypełnienie obowiązkowe)"
                required={true}
              />
            </div>

            <div className='mb-1'>
              <label className="form-label">Imię *</label>
              <input
                type="text"
                className="form-control my-0 h2 rounded-3"
                name='firstName'
                value={firstName}
                onChange={handleInput}
                maxLength="40"
                placeholder="Imię (wypełnienie obowiązkowe)"
                required={true}
              />
            </div>

            <div className='mb-2'>
              <label className="form-label">Nazwisko *</label>
              <input
                type="text"
                className="form-control my-0 h2 rounded-3"
                name='lastName'
                value={lastName}
                onChange={handleInput}
                maxLength="40"
                placeholder="Nazwisko (wypełnienie obowiązkowe)"
                required={true}
              />
            </div>

            <div className='d-flex flex-row'>
              <button type="submit" className="btn btn-outline-primary w7 h2 rounded-2" style={{ width: '140px' }} onClick={() => navigate('/Login', { replace: true })}>
                Anuluj
              </button>

              {valid ? (
                <button type="submit" className="btn btn-primary w7 h2 rounded-2 ms-auto" style={{ width: '140px' }} onClick={handleUserRegister}>
                  Zarejestruj
                </button>
              ) : (
                <button type="submit" className="btn btn-primary w7 h2 rounded-2 ms-auto" style={{ width: '140px' }} disabled>
                  Zarejestruj
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserRegistration;