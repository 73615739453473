import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import { getApplicationContent, getApplicationMetadata } from '../../services/Application';
import LoadingSpinner from './LoadingSpinner';
import CardHeader from './CardHeader';

const ApplicationPayment = ({ communicationHandler, registerCallback }) => {
    const { accessToken, checkPermission, userId } = useAuth();
    let { step, id } = useParams();
    let navigate = useNavigate();
    const [items, setItems] = useState([true, false, false, false, false, false]);
    const [selectAll, setSelectAll] = useState(false);

    const [loading, setLoading] = useState(true);
    const itemsDescriptions = [
        "Podstawowa obsługa * - 100,-",
        "Wydruk standardowy - 10,-",
        "Wydruk premium - 25,-",
        "Wysyłka na wskazany adres - 25,-",
        "Dodatkowa kopia - 15,-",
        "Powiadomienia SMS - 10,-"];

    const handleSelectAllStatement = (e) => {
        const isChecked = e.target.checked;
        const updatedStatements = items.map(() => isChecked);
        setItems(updatedStatements);
        setSelectAll(isChecked);
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await getApplicationContent(id, accessToken);
            } catch (error) {
                console.error('Failed to fetch application content:', error);
            }
        };

        fetchData();
        setLoading(false);
    }, []);

    return (
        <div className='pb-4'>
            <CardHeader text="Opłata za obsługę wniosku" onlyText="true" />
            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-column'>
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <div>
                            <div className='mb-1'>
                                Wybierz usługi do opłacenia
                            </div>

                            <div className='mb-1'>

                                <div className='d-flex flex-row pb-1'>
                                    <input className="form-check-input" type='checkbox' id={"statement_selectAll"} checked={selectAll} onChange={handleSelectAllStatement} />
                                    <label className='form-check-label' htmlFor={"statement_selectAll"}><strong>Zaznacz wszystkie</strong></label>
                                </div>

                                {itemsDescriptions.map((item, index) => (
                                    <div className='d-flex flex-row pb-1' key={"statement_" + index}>
                                        <input className="form-check-input" type='checkbox' id={"statement_" + index} checked={items[index]} />
                                        <label className='form-check-label' htmlFor={"statement_" + index}>{item}</label>
                                    </div>
                                )
                                )}
                            </div>

                            <div className='mb-1'>
                                Razem: <strong>666,-</strong>
                            </div>

                            <div className='d-flex flex-row'>
                                <button type="submit" className="btn btn-primary rounded-2 w7 h2 ms-auto">
                                    Zapłać
                                </button>
                            </div>

                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ApplicationPayment;