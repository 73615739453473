import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import 'bootstrap-icons/font/bootstrap-icons.css';

function Sidebar() {

    const [isSidebarVisible, setIsSidebarVisible] = useState(false);

    const handleResize = () => {
        if (window.innerWidth >= 992) {
            setIsSidebarVisible(true);
        } else {
            setIsSidebarVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Wywołanie przy montowaniu komponentu

        return () => window.removeEventListener('resize', handleResize); // Oczyszczenie przy demontowaniu
    }, []);

    return (
        <div>
            <div className={`${isSidebarVisible ? '' : 'visually-hidden'}`}>
                <SideMenu />
            </div>
            <div className={`${isSidebarVisible ? 'visually-hidden' : ''}`}>
                <div>
                    <div className={`offcanvas offcanvas-start ${isSidebarVisible ? 'show' : ''}`} tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
                        <div className='p-1' style={{ width: "100%" }}>
                            <a href="/" className="me-6 text-white text-decoration-none">
                                <span className="fs-4">eCoO</span>
                            </a>
                            {/* <button type="button" className="btn-close" data-bs-dismiss="offcanvas" style={{ color: "#ffffff", horizontalAlign: "right" }} aria-label="Close">X</button> */}
                        </div>
                        <div>
                            <SideMenu />
                        </div>
                    </div>
                    <button className="btn btn-primary floating-button" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu">
                    <i className='bi-list' style={{fontSize: "30px"}}></i>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
