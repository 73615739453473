import 'bootstrap-icons/font/bootstrap-icons.css';
import React, { useEffect, useState } from 'react';
import { getChambers, getRoles } from '../../services/Dictionary';
import { addUser, assignChamber, assignRole, editUser, getAssignedRoles, getUser, removeRole } from '../../services/User';
import { validateEmail, validateLetters, validateLettersDigitsSpecChars } from '../../utils/Validators';
import ValidationInfo from './ValidationInfo';

function UserData({ userId, readOnly = false, handleBack, handleAfterSave }) {
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [chamber, setChamber] = useState("");
    const [chambers, setChambers] = useState([]);
    const [userRoles, setUserRoles] = useState({});
    const [roles, setRoles] = useState([]);

    const [validators, setValidators] = useState({
        email: "false",
        firstName: "false",
        lastName: "false"
    });

    const handleSave = async () => {
        try {
            let result = false;
            if (userId === "00000000-0000-0000-0000-000000000000")
                result = await addUser(email, firstName, lastName);
            else
                result = await editUser(userId, email, firstName, lastName);
    
            if (result){
                await assignChamber(email, chamber);
                if (userRoles && Object.keys(userRoles).length > 0) {
                    const rolePromises = Object.entries(userRoles).map(([roleId, isChecked]) => {
                        if (isChecked) {
                            console.log(`Assigning role ${roleId} to user ${email}`);
                            return assignRole(email, roleId);
                        } else {
                            console.log(`Removing role ${roleId} from user ${email}`);
                            return removeRole(email, roleId);
                        }
                    });
                    await Promise.all(rolePromises);
                }
            }
            handleAfterSave();
        } catch (error) {
            console.error('Błąd podczas zapisywania danych użytkownika:', error);
        }
    };
    

    const handleFieldInput = (e) => {
        let { name, value } = e.target;
        if (e.target.type === "checkbox"){
            value = e.target.checked;
            name = "roles";
        }

        switch (name) {
            case "email":
                if (validateLettersDigitsSpecChars(value, 254, true) || value === "")
                    setEmail(value);
                break;
            case "firstName":
                if (validateLetters(value, 40, true) || value === "")
                    setFirstName(value);
                break;
            case "lastName":
                if (validateLetters(value, 40, true) || value === "")
                    setLastName(value);
                break;
            case "chamber":
                setChamber(value);
                break;
            case "roles":
                setUserRoles({...userRoles, [e.target.name]: value});
                break;
            default:
        }
    };

    const validateField = (name, value) => {
        console.log("vF: " + name);
        switch (name) {
            case "email":
                return validateEmail(value, true) ? "false" : "true";
            case "firstName":
                return validateLetters(value, 40, true) ? "false" : "true";
            case "lastName":
                return validateLetters(value, 40, true) ? "false" : "true";
            default:
                return "true";
        }
    };

    const handleValidate = (e) => {
        const { name, value } = e.target;
        console.log("UD - hV: " + name);
        setValidators(prevValidators => ({
            ...prevValidators,
            [name]: validateField(name, value)
        }));
        console.log(validators);
    };

    useEffect(() => {
        const fetchDictionaryData = async () => {
            try {
                const chambersList = await getChambers();
                setChambers(chambersList);

                const rolesList = await getRoles();
                setRoles(rolesList);
            } catch (error) {
                console.error('Błąd podczas pobierania danych słownikowych:', error);
            }
        };

        fetchDictionaryData();
    },[]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                if (userId !== "00000000-0000-0000-0000-000000000000") {
                    console.log("Tryb edycji");
                    const user = await getUser(userId);
                    if (user) {
                        setEmail(user.Email);
                        setFirstName(user.FirstName);
                        setLastName(user.LastName);
                        setChamber(user.ChamberId);

                        if (user.Email) {
                            const assignedRoles = await getAssignedRoles(user.Email);
                            if (assignedRoles) {
                                const rolesState = {};
                                assignedRoles.forEach(role => {
                                    rolesState[role.Id] = true;
                                });
                                setUserRoles(rolesState);
                            }
                        }
                    }
                    else {
                        console.log("Tryb dodawania " + userId);
                        setEmail("");
                        setFirstName("");
                        setLastName("");
                    }
                }
            } catch (error) {
                console.error('Błąd podczas pobierania danych użytkownika:', error);
            }
        };

        fetchUserData();
    }, [userId]);

    return (
            <div>
                {email || email === "" ? (
                    <div className='d-flex flex-column'>
                        <div className='mb-1'>
                            <div className='d-flex align-items-center'>
                                <label className="form-label">Adres email *</label>
                                <ValidationInfo title="Wypełnienie pola jest obowiązkowe. Maksymalna ilość znaków - 254" visible={validators.email} />
                            </div>

                            <input
                                type="text"
                                className="form-control my-0 h2 rounded-3"
                                maxLength="254"
                                name="email"
                                value={email}
                                onChange={handleFieldInput}
                                onBlur={handleValidate}
                                placeholder="Adres email użytkownika (np. imie_nazwisko@domena.com)"
                                required={true}
                                invalid={validators.email}
                            />
                        </div>

                        <div className='d-flex flex-row mb-1'>
                            <div className='w23'>
                                <div className='d-flex align-items-center'>
                                    <label className="form-label">Imię *</label>
                                    <ValidationInfo title="Wypełnienie pola jest obowiązkowe. Maksymalna ilość znaków - 40" visible={validators.firstName} />
                                </div>

                                <input
                                    type="text"
                                    className="form-control my-0 h2 rounded-3"
                                    maxLength="40"
                                    name="firstName"
                                    value={firstName}
                                    onChange={handleFieldInput}
                                    onBlur={handleValidate}
                                    placeholder="Imię użytkownika"
                                    required={true}
                                    invalid={validators.firstName}
                                />
                            </div>

                            <div className='ms-1 w23'>
                                <div className='d-flex align-items-center'>
                                    <label className="form-label">Nazwisko *</label>
                                    <ValidationInfo title="Wypełnienie pola jest obowiązkowe. Maksymalna ilość znaków - 40" visible={validators.lastName} />
                                </div>

                                <input
                                    type="text"
                                    className="form-control my-0 h2 rounded-3"
                                    maxLength="40"
                                    name="lastName"
                                    value={lastName}
                                    onChange={handleFieldInput}
                                    onBlur={handleValidate}
                                    placeholder="Nazwisko użytkownika"
                                    required={true}
                                    invalid={validators.lastName}
                                />
                            </div>
                        </div>

                        <div className='mb-1'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center'>
                                    <label className="form-label">Izba</label>
                                </div>

                                <select
                                    className="form-control my-0 h2 rounded-3"
                                    name="chamber"
                                    value={chamber}
                                    onChange={handleFieldInput}
                                    onBlur={handleValidate}
                                    required="false"
                                >
                                    <option value="">Brak</option>
                                    {chambers.map(c => (
                                        <option key={c.Id} value={c.Id}>
                                            {c.Name} ({c.City})
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                            <div className='d-flex flex-column mb-2'>
                                <div className='d-flex align-items-center'>
                                    <label className="form-label">Role</label>
                                </div>

                                <div>
                                {roles ? roles.map((role, index) => (
                                    <div key={index} className='mb-1'>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={role.Id}
                                            name={role.Id}
                                            checked={userRoles[role.Id] || false}
                                            onChange={handleFieldInput}
                                            title={role.Name}
                                        />
                                        <label className="form-check-label" htmlFor={role.Id}>{role.Name}</label>
                                    </div>
                                )) : (
                                    <div>
                                        Pobieranie danych...
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="d-flex flex-column flex-wrap">
                            <div className="d-flex flex-row w-100">
                                <button className="btn btn-outline-primary h2 rounded-2 me-auto w7" onClick={handleBack}>
                                    Anuluj
                                </button>
                                <button type="button" className="btn btn-primary h2 rounded-2 w7" onClick={handleSave}>
                                    {userId === "00000000-0000-0000-0000-000000000000" ? "Dodaj" : "Zapisz"}
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        Pobieranie danych...
                    </div>
                )}
            </div>
    );
}

export default UserData;
