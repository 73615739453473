function validateNIP(nip, required = true) {
    if (!required && !nip)
        return true;

        nip = nip.replace(/[\s-]/g, ''); // Usuwanie spacji i myślników

    if (nip.length !== 10 || !/^\d+$/.test(nip)) {
        return false; // Sprawdzenie, czy NIP ma 10 cyfr
    }

    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7]; // Wagi dla poszczególnych cyfr NIP
    const controlSum = nip
        .substring(0, 9)
        .split('')
        .reduce((sum, digit, index) => sum + parseInt(digit) * weights[index], 0);

    const controlNumber = controlSum % 11;
    const controlDigit = parseInt(nip[9]);

    return controlNumber === controlDigit;
}

function validateProductName(productName, required = true) {
    if (!required && !productName)
        return true;
    if (!productName || productName.length > 110)
        return false;
    const regex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/;
    return regex.test(productName);
}

function validateLettersDigitsSpecChars(value, maxLength, required = true) {
    if (!required && !value)
        return true;
    if (!value || value.length > maxLength)
        return false;
    const regex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/;
    return regex.test(value);
}

function validateLettersDigits(value, maxLength, required = true) {
    if (!required && !value)
        return true;
    if (!value || value.length > maxLength)
        return false;
        const regex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 .,\/\\-]+$/;
        return regex.test(value);
}

function validateLetters(value, maxLength, required = true) {
    if (!required && !value)
        return true;
    if (!value || value.length > maxLength)
        return false;
        const regex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]+$/;
        return regex.test(value);
}

function validateDigits(value, maxLength, required = true) {
    if (!required && !value)
        return true;
    if (!value || value.length > maxLength)
        return false;
        const regex = /^[0-9 ]+$/;
        return regex.test(value);
}

function validateBusinessName(businessName, required = true) {
    if (!required && !businessName)
        return true;
    if (!businessName || businessName.length > 160)
        return false;
    const regex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/;
    return regex.test(businessName);
}

function validateStreet(street, required = true) {
    if (!required && !street)
        return true;
    if (!street || street.length > 66)
        return false;
    const regex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 .,\/\\-]+$/;
    return regex.test(street);
}

function validateBuilding(building, required = true) {
    if (!required && !building)
        return true;
    if (!building || building.length > 9)
        return false;
    const regex = /^\d+[a-zA-Z\d,[\]/ -]*$/;
    return regex.test(building);
}

function validateAppartment(appartment, required = false) {
    if (!required && !appartment)
        return true;
    if (!appartment || appartment.length > 11)
        return false;
    const regex = /^\d+[a-zA-Z\d,[\]/ -]*$/;
    return regex.test(appartment);
}

function validateCity(city, required = true) {
    if (!required && !city)
        return true;
    if (!city || city.length > 30)
        return false;
    const regex = /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9\s,-./[\]]+$/;
    return regex.test(city);
}

function validateCountryCode(countryCode, required = true) {
    if (!required && !countryCode)
        return true;
    if (!countryCode || countryCode.length > 2)
        return false;
    const regex = /^[a-zA-Z]/;
    return regex.test(countryCode);
}

function validatePostCode(postCode, required = true, international = false) {
    if (!required && !postCode)
        return true;

    if (!postCode)
        return false;

    // międzynarodowy kod pocztowy
    if (international) {
        if (postCode.length > 11)
            return false;
        return true;
    }

    // walidacja zgodnie z 
    if (!/^\d{2}-\d{3}$/.test(postCode))
        return false;
    return true;
}

function validateEmail(email, required = true) {
    if (!required && !email)
        return true;
    if (!email || email.length > 254)
        return false;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

export { validateNIP, validateBusinessName, validateStreet, validateBuilding, validateAppartment, validateCity, validateCountryCode, validatePostCode, validateProductName, validateLettersDigits, validateLettersDigitsSpecChars, validateDigits, validateLetters, validateEmail };