import React, { useState, useEffect } from 'react';
import Sidebar from '../components/layout/Sidebar'
import ApplicationList from '../components/common/ApplicationList';
import { useAuth } from '../context/AuthContext';
import CardHeader from '../components/common/CardHeader';

function Applications() {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const { checkPermission } = useAuth();

    const handleResize = () => {
        if (window.innerWidth >= 992) {
            setIsSidebarVisible(true);
        } else {
            setIsSidebarVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        //handleResize(); // Wywołanie przy montowaniu komponentu

        return () => window.removeEventListener('resize', handleResize); // Oczyszczenie przy demontowaniu
    }, []);

    return (
        <main className="d-flex flex-row" style={{ height: '100vh' }}>
            <Sidebar />
            <div className="content">
                <CardHeader text={"Moje wnioski" + (checkPermission("Obsługa wniosków") ? " (operator)" : "")} onlyText="true" />
                <ApplicationList operator={checkPermission("Obsługa wniosków")} />
            </div>
        </main>
    );
}

export default Applications;
