import React from 'react';
import { useEffect, useState } from 'react';

function InfoPopup({ title, trigger, warning = false }) {
    const [showAlert, setShowAlert] = useState(false);

    function showInfoPopup() {
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 5000);
    }

    useEffect(() => {
        if (trigger > 0) {
            console.log("InfoPopup, wyzwalacz - " + trigger);
            showInfoPopup();
        }
    }, [trigger]);

    return (
        <div className={`alert` + (warning === false ? ` alert-success ` : ` alert-warning `) + `alert-dismissible d-flex align-items-center fixed-top w-25 ms-auto fade ${showAlert ? "elementFromRight" : ""}`} style={{marginTop: '125px', zIndex: showAlert ? "100" : "-100"}} role="alert">
            <div>
                {title}
            </div>
            <button type="button" className="btn-close" onClick={() => setShowAlert(false)} aria-label="Close"></button>
        </div>
    );
}

export default InfoPopup;