import React, { useEffect, useState } from 'react';
import Sidebar from '../components/layout/Sidebar';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ImageComponent from '../components/common/ImageComponent';
import CertificatePreview from '../components/common/CertificatePreview';
import { ApiHost } from '../utils/ApiConfig';
import CardHeader from '../components/common/CardHeader';

export const CheckCertificateEmpty = () => {
    let navigate = useNavigate();

    React.useEffect(() => {
        navigate('/certificate/check/0');
    }, [navigate]);

    return null; // Możesz tu zwrócić jakiś komponent ładowania, jeśli chcesz
};

const CheckCertificate = () => {
    const { accessToken } = useAuth();
    const [certId, setCertId] = useState('');
    let navigate = useNavigate();
    const [certificate, setCertificate] = useState('');

    let { certificateId } = useParams();
    // if (!isLoggedIn)
    //     navigate('/Login', { replace: true });

    // eslint-disable-next-line no-extend-native
    String.prototype.isLetterOrDigit = function () {
        return (/^([a-zA-Z0-9]{1})$/gi).test(this);
    };

    const handleTypeIn = async (e) => {
        e.preventDefault();
        const newLength = e.target.value.length;
        const oldLength = certId.length;
        console.log(oldLength + " -> " + newLength);

        if (newLength < oldLength) {
            if (((e.target.value.length === 5) && (certId.length === 6)) || ((e.target.value.length === 10) && (certId.length === 11))) {
                e.target.value = e.target.value.slice(0, certId.length - 2);
            }
            setCertId(e.target.value);
        }
        else {
            const newLetter = e.target.value.substr(e.target.value.length - 1, 1).toUpperCase();
            if (newLetter.isLetterOrDigit()) {
                if ((certId.length === 4) || (certId.length === 9))
                    setCertId((certId + '-' + newLetter));
                else
                    setCertId(certId + newLetter)
            }
        }
    };

    const handleOk = async (e) => {
        e.preventDefault();
        navigate('/certificate/check/' + certId);
    };

    useEffect(() => {
        console.log(certificateId);
        if (certificateId === 0)
            setCertId('');
    }, [certificateId]); // Zależności useEffect


    console.log("Pobieranie świadectwa: " + certId);

    return (
        <main className="d-flex flex-nowrap" style={{ height: '100vh' }}>
            <div className="d-flex flex-column">
                <Sidebar />
            </div>
            <div className="content">
                <CardHeader text="Podgląd świadectwa pochodzenia" onlyText="true" />

                <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                    <div className='d-flex flex-column'>
                        {(certificateId !== '0') && (
                            <div>
                                <span>Identyfikator świadectwa: <strong>{certificateId}</strong></span>
                                <div style={{ maxHeight: '80%', overflowY: 'auto' }}>
                                    <CertificatePreview className="mw-100" applicationId={certificateId} />
                                </div>
                                {/* <div className="me-auto spinner-border" role="status">
                                <span className="visually-hidden">Pobieranie danych</span>
                                <br />
                            </div> */}
                            </div>
                        )
                        }
                        {(certificateId === '0') && (
                            <div>
                                <span>Wprowadź dane świadectwa, aby wyświetlić jego podgląd.</span>
                                <div className="mb-1">{certificate}</div>
                                <div className="flex-column">
                                    <form onSubmit={handleOk}>
                                        <div className="d-flex flex-row flex-wrap">
                                            <label className="form-label">Identyfikator świadectwa</label>
                                            <input type="text" className="form-control mb-4 h2 rounded-3" maxLength="14" value={certId} onChange={(e) => handleTypeIn(e)} pattern='^[0-9A-Za-z]{4}-[0-9A-Za-z]{4}-[0-9A-Za-z]{4}$' placeholder="np. 96B0-2B56-C27F" required={true} />
                                            <button type="submit" className="btn btn-primary w7 h2 rounded-2 ms-auto" disabled={certId.replace(/-/g, '').length !== 12}>
                                                Ok
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )
                        }
                    </div>
                </div>
            </div>
        </main>
    );
};

export default CheckCertificate;
