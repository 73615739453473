import React, { useState, useEffect } from 'react';
import Sidebar from '../components/layout/Sidebar'
import UserList from '../components/common/UserList';
import { useAuth } from '../context/AuthContext';
import AccessDenied from '../components/common/AccessDenied';
import PermissionCheckInProgress from '../components/common/PermissionCheckInProgress';

function UserManagement() {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [permissionCheckCompleted, setPermissionCheckCompleted] = useState(false);
    const { checkPermission } = useAuth();

    const permissionCheck = () => {
        setPermissionCheckCompleted(true);
    };

    const handleResize = () => {
        if (window.innerWidth >= 992) {
            setIsSidebarVisible(true);
        } else {
            setIsSidebarVisible(false);
        }
    };

    useEffect(() => {
        permissionCheck();
        window.addEventListener('resize', handleResize);
        //handleResize(); // Wywołanie przy montowaniu komponentu

        return () => window.removeEventListener('resize', handleResize); // Oczyszczenie przy demontowaniu
    }, []);

    return (
        <main className="d-flex flex-row" style={{ height: '100vh' }}>
            <Sidebar />
            {permissionCheckCompleted ?
                (
                    checkPermission("Zarządzanie użytkownikami") ? (
                    <div className="content">
                        <h1>Użytkownicy</h1>
                        <div>
                            W tym widoku możesz zarządzać użytkownikami systemu.
                        </div>
                        <UserList />
                    </div>
                    ) : (
                        <AccessDenied />
                    )
                ) : (
                    <PermissionCheckInProgress />
                )}
        </main>
    );
}

export default UserManagement;
