import 'bootstrap-icons/font/bootstrap-icons.css';
import React, { useEffect, useState } from 'react';
import { json, useParams } from 'react-router-dom';
import { assignChamber, assignUser, getApplicationMetadata, getApplicationStatus, setApplicationStatus } from '../../services/Application';
import { addInputToStep, commitStep, getStep } from '../../services/Process';
import { useAuth } from '../../context/AuthContext';
import LoadingSpinner from './LoadingSpinner';
import { operatorCanRunProcess, clientCanRunProcess } from './ReadOnly';

function ProcessPanel({ isVisible }) {

    let { step, id } = useParams();
    const [stepDescription, setStepDescription] = useState();
    const [stepDestinations, setStepDestinations] = useState();
    const [selectedValue, setSelectedValue] = useState();
    const [displayName, setDisplayName] = useState();
    const [statusId, setStatusId] = useState();
    const [status, setStatus] = useState();
    const [statusFront, setStatusFront] = useState();
    const [needsReload, setNeedsReload] = useState(0);
    const { checkPermission } = useAuth();

    const handleNext = async () => {
        if (stepDestinations.length === 1) {
            await performCommit();
        } else if (stepDestinations.length > 1) {
            await performInputValueAndCommit();
        }
        setSelectedValue(null);
    };
    
    const performCommit = async () => {
        try {
            const result = await commitStep(id);
            setNeedsReload(prev => prev + 1);
            console.log("commitStep - PP:", result);
        } catch (error) {
            console.error("Error committing step:", error);
        }
    };
    
    const performInputValueAndCommit = async () => {
        try {
            const result = await addInputToStep(id, selectedValue);
            setNeedsReload(prev => prev + 1);
            console.log("addInputToStep - PP:", result);
        } catch (error) {
            console.error("Error adding input to step:", error);
        }
    };

    const handleSelect = (value) => {
        setSelectedValue(value);
    };

    useEffect(() => {
        console.log("needsReload changed:", needsReload);
        const fetchData = async () => {
            try {
                const stepData = await getStep(id);
                setStepDescription(stepData.Step.id + ": " + stepData.Step.description);
                setStepDestinations(stepData.Destinations);

                let tempMetadata = await getApplicationMetadata(id);
                setStatusId(tempMetadata.status);
                setStatus(tempMetadata.statusNameInternal);
                setStatusFront(tempMetadata.statusName);
                setDisplayName(tempMetadata.displayName);
            } catch (error) {
                console.error("Error fetching step data:", error);
            }
        };
    
        fetchData();
    }, [needsReload, id]);

    useEffect(() => {
        console.log("PP - uE - selectedValue: " + selectedValue);
    }, [selectedValue])

    if (isVisible)
        return (
            <div className='d-flex flex-column pe-1' style={{ width: "300px" }}>
                <h3>OBSŁUGA WNIOSKU</h3>
                <div className='ms-auto very-small'>
                {stepDescription ? 
                (
                    stepDescription.split(": ")[0]
                ) : (
                    <LoadingSpinner />
                )}
                </div>


                <div className='mb-1'>
                    <label className="form-label">Nazwa robocza</label>
                    {displayName ? (
                        <div className="form-control my-0 h2 rounded-3" style={{ background: "white" }}>{displayName}</div>
                    ) : (
                        <div className="form-control my-0 h2 rounded-3" style={{ background: "white" }}><LoadingSpinner /></div>
                    )}
                </div>

                {!checkPermission("Obsługa wniosków") && (
                    <div className='mb-1'>
                    <label className="form-label">Status</label>
                    {statusFront ? (
                        <div className="form-control my-0 h2 rounded-3" style={{ background: "white" }}>{statusFront}</div>
                    ) : (
                        <div className="form-control my-0 h2 rounded-3" style={{ background: "white" }}><LoadingSpinner /></div>
                    )}
                </div>
                )}

                {checkPermission("Obsługa wniosków") && (
                <div className='mb-1'>
                    <label className="form-label">Status wewnętrzny</label>
                    {status ? (
                        <div className="form-control my-0 h2 rounded-3" style={{ background: "white" }}>{status}</div>
                    ) : (
                        <div className="form-control my-0 h2 rounded-3" style={{ background: "white" }}><LoadingSpinner /></div>
                    )}
                </div>
                )}

                {((checkPermission("Obsługa wniosków") && operatorCanRunProcess(statusId))
                || (!checkPermission("Obsługa wniosków") && clientCanRunProcess(statusId))) && (
                <div className='mb-1'>
                    {stepDestinations && (
                        <div className="form-label"><strong>{stepDescription.split(": ")[1]}</strong></div>
                    )}

                    {stepDestinations ?
                        (
                            stepDestinations.map((sd, index) => (
                                <div className='form-control rounded-3 w-100 p-1 mb-1' style={{ background: "white" }} key={index}>
                                    <div className='d-flex flex-row'>
                                        <input type="radio" className='form-check-input me-1' style={{ cursor: "pointer" }} name={"processOption" + index} id={"processOption" + index} checked={selectedValue === sd.stepId} onChange={() => handleSelect(sd.stepId)} />

                                        <label className="form-check-label" style={{ cursor: "pointer" }} htmlFor={"processOption" + index}>
                                            {sd.description}
                                        </label>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <LoadingSpinner />
                        )
                    }
                </div>
                )}

                {((checkPermission("Obsługa wniosków") && operatorCanRunProcess(statusId))
                || (!checkPermission("Obsługa wniosków") && clientCanRunProcess(statusId))) && (
                <div className='d-flex flex-row'>
                    {selectedValue ? (
                    <button className='btn btn-primary ms-auto w7' onClick={handleNext}>
                        Dalej
                    </button>
                    ) : (
                    <button className='btn btn-primary ms-auto w7' disabled>
                        Dalej
                    </button>
                    )}
                </div>
                )}
            </div>
        );
}

export default ProcessPanel;
