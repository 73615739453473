import React, { useState, useEffect } from 'react';
import HelpTooltip from './HelpTooltip';
import ValidationInfo from './ValidationInfo';
import { validateLettersDigits, validateLettersDigitsSpecChars } from '../../utils/Validators';
import CommunicationSpot from './CommunicationSpot';

const tooltips = [
    "Możesz podać dane liczbowo-opisowe,<br/>na przykład: <br/>1 kontener, <br/>3 palety, <br/>200 litrów",
    "Możesz podać łączną wagę netto",
    "Możesz podać łączną wagę brutto"
];

function ProductSummary({ data = {}, onDataChange, communicationHandler, registerCallback }) {
    let {
        totalQuantity = '',
        totalNetWeight = '',
        totalGrossWeight = '',
        transportDetails = '',
        productsRemarks = '',
        productsSummaryEdit = '',
        quantitySummaryEdit = '',
        editSummaries = false
    } = data;

    const [validators, setValidators] = useState({
        totalQuantity: "",
        totalNetWeight: "",
        totalGrossWeight: "",
        transportDetails: "",
        productsRemarks: ""
    });
    const [isLoading, setIsLoading] = useState(true);

    const validateField = (name, value) => {
        switch (name) {
            case "totalQuantity":
                return validateLettersDigits(value, 33, false) ? "false" : "true";
            case "totalNetWeight":
                return validateLettersDigits(value, 22, false) ? "false" : "true";
            case "totalGrossWeight":
                return validateLettersDigits(value, 20, false) ? "false" : "true";
            case "transportDetails":
                return validateLettersDigitsSpecChars(value, 280, false) ? "false" : "true";
            case "productsRemarks":
                return validateLettersDigitsSpecChars(value, 360, false) ? "false" : "true";
            default:
                return "true";
        }
    };

    const handleProductDataChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;
        onDataChange(name, newValue);
        setValidators(prevValidators => ({
            ...prevValidators,
            [name]: validateField(name, newValue)
        }));
    };

    useEffect(() => {
        setValidators({
            totalQuantity: validateField("totalQuantity", totalQuantity),
            totalNetWeight: validateField("totalNetWeight", totalNetWeight),
            totalGrossWeight: validateField("totalGrossWeight", totalGrossWeight),
            transportDetails: validateField("transportDetails", transportDetails),
            productsRemarks: validateField("productsRemarks", productsRemarks)
        });
        setIsLoading(false);
    }, [totalQuantity, totalNetWeight, totalGrossWeight, transportDetails, productsRemarks]);

    return (
        <div>
            {isLoading ?
                (<div>Ładowanie danych...</div>)
                :
                (
                    <div className="d-flex flex-column">
                        <div className='d-flex flex-row'>
                            <h4>Produkty / Towary - ilość, waga, opis</h4>
                        </div>
                        <div className="d-flex flex-row mb-1">
                            <div className='w23'>
                                <div className='d-flex align-items-center'>
                                    <label className="form-label">Ilość łącznie</label>
                                    <HelpTooltip title={tooltips[0]} />
                                    <ValidationInfo title="Maksymalna ilość znaków - 33" visible={validators.totalQuantity} />
                                </div>
                                <input
                                    type="text"
                                    className="form-control my-0 w23 h2 rounded-3"
                                    maxLength="33"
                                    name="totalQuantity"
                                    value={totalQuantity}
                                    onChange={handleProductDataChange}
                                    placeholder="Wpisz łączną ilość (np. 13 szt./pcs.)"
                                    invalid={validators.totalQuantity}
                                />
                            </div>

                            <div className='ms-1 w11'>
                                <div className='d-flex align-items-center'>
                                    <label className="form-label">Łączna waga netto</label>
                                    <HelpTooltip title={tooltips[1]} />
                                    <ValidationInfo title="Maksymalna ilość znaków - 22" visible={validators.totalNetWeight} />
                                </div>
                                <input
                                    type="text"
                                    className="form-control my-0 w11 h2 rounded-3"
                                    maxLength="22"
                                    name="totalNetWeight"
                                    value={totalNetWeight}
                                    onChange={handleProductDataChange}
                                    placeholder="Wpisz łączną wagę (np. 7 t)"
                                    invalid={validators.totalNetWeight}
                                />
                            </div>

                            <div className='ms-1 w11'>
                                <div className='d-flex align-items-center'>
                                    <label className="form-label">Łączna waga brutto</label>
                                    <HelpTooltip title={tooltips[2]} />
                                    <ValidationInfo title="Maksymalna ilość znaków - 20" visible={validators.totalGrossWeight} />
                                </div>
                                <input
                                    type="text"
                                    className="form-control my-0 w11 h2 rounded-3"
                                    maxLength="20"
                                    name="totalGrossWeight"
                                    value={totalGrossWeight}
                                    onChange={handleProductDataChange}
                                    placeholder="Wpisz łączną wagę (np. 7 t)"
                                    invalid={validators.totalGrossWeight}
                                />
                            </div>
                        </div>

                        <div className="d-flex flex-row pb-2 mb-1 border-bottom">
                            <div className='d-flex flex-column w23'>
                                <div className='d-flex align-items-center'>
                                    <label className="form-label">Szczegóły dotyczące transportu produktów / towarów</label>
                                    <ValidationInfo title="Maksymalna ilość znaków - 280" visible={validators.transportDetails} />
                                </div>
                                <textarea
                                    className="form-control my-0 w23 h8 rounded-3"
                                    maxLength="280"
                                    name="transportDetails"
                                    value={transportDetails}
                                    onChange={handleProductDataChange}
                                    placeholder="Szczegóły dotyczące transportu produktów / towarów (pole nr 4 na Świadectwie)"
                                    invalid={validators.transportDetails}
                                />
                                <span className="ms-auto small">pole nr 4 na świadectwie</span>
                            </div>

                            <div className='d-flex flex-column ms-1 w23'>
                                <div className='d-flex align-items-center'>
                                    <label className="form-label">Uwagi dotyczące produktów / towarów</label>
                                    <ValidationInfo title="Maksymalna ilość znaków - 360" visible={validators.productsRemarks} />
                                </div>
                                <textarea
                                    className="form-control my-0 w23 h8 rounded-3"
                                    maxLength="360"
                                    name="productsRemarks"
                                    value={productsRemarks}
                                    onChange={handleProductDataChange}
                                    placeholder="Uwagi dotyczące produktów / towarów (pole nr 5 na Świadectwie)"
                                    invalid={validators.productsRemarks}
                                />
                                <span className="ms-auto small">pole nr 5 na świadectwie</span>
                            </div>
                        </div>

                        <h4>Produkty / Towary - podsumowanie</h4>
                        <div className="d-flex flex-row my-1">
                            <p>
                                Powyżej uzupełniłeś dane towarów i produktów objętych świadectwem. Wykorzystamy je w celu potwierdzenia ich pochodzenia.<br />
                                Na podstawie podanych danych, pola 6 i 7 na świadectwie będą wyglądały jak poniżej.<br />
                                Sprawdź czy wszystko się zgadza i niczego nie brakuje. Jeżeli musisz poprawić błędy - wróć do odpowiedniej sekcji powyżej.<br />
                                W uzasadnionych przypadkach (np. korzystanie z akredytywy) Twój partner handlowy może wymagać specyficznych zapisów na świadectwie. W&nbsp;takiej sytuacji możesz zaproponować ich treść edytując pola poniżej. Decyzję o akceptacji tych zapisów lub ich edycji podejmie operator analizujący Twój wniosek.
                            </p>
                        </div>

                        <div className="d-flex flex-row mb-1">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="editSummaries" name="editSummaries" onChange={handleProductDataChange} />
                                <label className="form-check-label" htmlFor="editSummaries">
                                    Chcę edytować widoczną na świadectwie listę produktów / towarów i ich ilości, wagę itp.
                                </label>
                            </div>
                        </div>

                        <div className="d-flex flex-row">
                            <div className='d-flex flex-column w35'>
                                {(editSummaries === false || editSummaries === "false") ? (
                                    <textarea className="form-control my-0 h8 rounded-3" maxLength="280" name="productsSummaryEdit" value={productsSummaryEdit} onChange={handleProductDataChange} placeholder="Lista produktów / towarów" disabled="true" />
                                ) : (
                                    <textarea className="form-control my-0 h8 rounded-3" maxLength="280" name="productsSummaryEdit" value={productsSummaryEdit} onChange={handleProductDataChange} placeholder="Lista produktów / towarów" />
                                )}
                                <span className="ms-auto small">pole nr 6 na świadectwie</span>
                            </div>

                            <div className='d-flex flex-column ms-1 w11'>
                                {(editSummaries === false || editSummaries === "false") ? (
                                    <textarea className="form-control my-0 h8 rounded-3" maxLength="360" name="quantitySummaryEdit" value={quantitySummaryEdit} onChange={handleProductDataChange} placeholder="Ilości" disabled="true" />
                                ) : (
                                    <textarea className="form-control my-0 h8 rounded-3" maxLength="360" name="quantitySummaryEdit" value={quantitySummaryEdit} onChange={handleProductDataChange} placeholder="Ilości" />
                                )}
                                <span className="ms-auto small">pole nr 7 na świadectwie</span>
                            </div>
                        </div>
                    </div>)}
        </div>
    );
}

export default ProductSummary;