import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import { getApplicationContent, getApplicationMetadata } from '../../services/Application';
import CertificatePreview from './CertificatePreview';
import LoadingSpinner from './LoadingSpinner';
import CardHeader from './CardHeader';

const ApplicationMetadata = ({ communicationHandler, registerCallback }) => {
    const { accessToken, checkPermission, userId } = useAuth();
    let { step, id } = useParams();
    let navigate = useNavigate();

    const [application, setApplication] = useState();
    const [status, setStatus] = useState(null);
    const [statusName, setStatusName] = useState(null);
    const [createDate, setCreateDate] = useState(null);
    const [displayName, setDisplayName] = useState(null);
    const [remarks, setRemarks] = useState(null);
    const [applicant, setApplicant] = useState(null);
    const [operator, setOperator] = useState(null);
    const [chamber, setChamber] = useState(null);
    const [exporterData, setExporterData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await getApplicationContent(id, accessToken);
                if (response) {
                    setApplication(response);

                    if (response.Step_1.Data.exporterCertificateData)
                        setExporterData(response.Step_1.Data.exporterCertificateData);
                    if (response.Step_5.Data.remarks)
                        setRemarks(response.Step_5.Data.remarks);
                }

                const metadata = await getApplicationMetadata(id, accessToken);
                if (metadata) {
                    setCreateDate(metadata.createDate.split("T")[0] + " " + metadata.createDate.split("T")[1].split(".")[0]);
                    setDisplayName(metadata.displayName);
                    setStatus(metadata.status);
                    setStatusName(metadata.statusName);
                    setApplicant(metadata.Applicant.firstName + " " + metadata.Applicant.lastName);
                    setOperator(metadata.Operator.firstName + " " + metadata.Operator.lastName);
                    setChamber(metadata.Chamber.name);
                }
            } catch (error) {
                console.error('Failed to fetch application content:', error);
            }
        };

        fetchData();
        setLoading(false);
    }, []);

    return (
        <div className='pb-4'>
            <CardHeader text="Dane wniosku" onlyText="true" />
            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-column'>
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <div>
                            <div className='mb-1 w-100'>
                                <label className="form-label">Nazwa robocza</label>
                                <div className="form-control my-0 h2 rounded-3" >
                                    {displayName}
                                </div>
                            </div>

                            <div className='mb-1 w-100'>
                                <label className="form-label">Data utworzenia</label>
                                <div className="form-control my-0 h2 rounded-3" >
                                    {createDate}
                                </div>
                            </div>

                            <div className='mb-1 w-100'>
                                <label className="form-label">Status</label>
                                <div className="form-control my-0 h2 rounded-3" >
                                    {statusName}
                                </div>
                            </div>

                            <div className='mb-1 w-100'>
                                <label className="form-label">Eksporter</label>
                                <div className="form-control my-0 h6 rounded-3" >
                                    {exporterData && exporterData.split("\n").map(row => (
                                        <span>
                                            {row} <br />
                                        </span>
                                    ))}
                                </div>
                            </div>

                            <div className='mb-1 w-100'>
                                <label className="form-label">Wnioskodawca</label>
                                <div className="form-control my-0 h2 rounded-3" >
                                    {applicant}
                                </div>
                            </div>

                            <div className='mb-1 w-100'>
                                <label className="form-label">Wniosek obsługuje</label>
                                <div className="form-control my-0 h2 rounded-3" >
                                    {chamber}
                                </div>
                            </div>

                            <div className='mb-1 w-100'>
                                <label className="form-label">Operator</label>
                                <div className="form-control my-0 h2 rounded-3" >
                                    {operator}
                                </div>
                            </div>

                            <div className='mb-1 w-100'>
                                <label className="form-label">Uwagi dla operatora wniosku</label>
                                <div className="form-control my-0 h2 rounded-3" >
                                    {remarks && remarks.split("\n").map(row => (
                                        <span>
                                            {row} <br />
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ApplicationMetadata;