import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateEmail, validateLetters, validateLettersDigitsSpecChars } from '../../utils/Validators';
import { addUser } from '../../services/User';

function UserRegistrationResult({success = false}) {
  const navigate = useNavigate();

  return (
    <div className="row justify-content-center justify-content-md-start">
      <div className="col-12 col-md-8 col-lg-6 col-xl-5 my-2 w-40">
        <span className="d-flex flex-column align-items-center mb-2">
          <img src="/assets/images/kig-e7Ti-vgY.png" className="mx-auto" alt="..." />
          <span className="text-white" style={{ fontSize: '0.75rem' }}>KRAJOWA IZBA GOSPODARCZA</span>
        </span>
        <div className="py-1 px-4 px-xl-5 bg-white rounded-4 w-100">
          <h1 className="display-4 text-center mb-1">{success ? "Aktywacja konta" : "Wystąpił błąd"}</h1>
          <p className="text-muted text-center mb-2">Elektroniczne świadectwo pochodzenia</p>
          {success ? (
          <div className='d-flex flex-column w-100'>
            <div className='mb-2'>
              Rejestracja danych konta powiodła się.<br/>
              W celu aktywacji otwórz link, który wysłaliśmy na podany wcześniej adres email.<br />
            </div>

            <div className='d-flex flex-row'>
              <button className="btn btn-primary h2 rounded-2 ms-auto w7" onClick={() => navigate("/Login", { replace: true })}>
                Zaloguj
              </button>
            </div>
          </div>
          ) : (
          <div className='d-flex flex-column w-100'>
            <div className='mb-2'>
              W trakcie rejestracji wystąpił błąd.<br/>
              Sprawdź wprowadzone dane i spróbuj ponownie lub zaloguj się do systemu przy użyciu podanego adresu email - być może konto zostało już wcześniej zarejestrowane.<br />
            </div>

            <div className='d-flex flex-row'>
              <button type="submit" className="btn btn-outline-primary h2 rounded-2 w7" onClick={() => navigate('/Register', { replace: true })}>
                Cofnij
              </button>

              <button className="btn btn-primary h2 rounded-2 ms-auto w7" onClick={() => navigate("/Login", { replace: true })}>
                Zaloguj
              </button>
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserRegistrationResult;