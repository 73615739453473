import React, { useEffect, useState } from 'react';
import Sidebar from '../components/layout/Sidebar';
import Consent from '../components/common/Consent';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ApiHost } from '../utils/ApiConfig';

const Consents = () => {
    const [consentIds, setConsentIds] = useState([]);
    const { accessToken } = useAuth();
    const { userId } = useAuth();
    const { isLoggedIn } = useAuth();
    let navigate = useNavigate();
    
    // if (!isLoggedIn)
    //     navigate('/Login', { replace: true });

    useEffect(() => {
        const getConsentIds = async () => {
            try {
                console.log("Pobieranie listy zgód dla użytkownika: " + userId)
                const response = await axios.post(ApiHost + '/users/consent/ids', {
                    user_id: userId
                }, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                .then(response => {
                    const receivedConsentIds = response.data.consentIds;
                    setConsentIds(receivedConsentIds);
                })
                .catch(error => console.error('Błąd pobierania consentIds:', error));
            } catch (error) {
                console.error('Błąd dostępu do chronionej metody', error);
            }
        };

        getConsentIds();
    }, [userId, accessToken]); // Zależności useEffect

    return (
        <main className="d-flex flex-nowrap" style={{ height: '100vh' }}>
            <div className="d-flex flex-column">
                <Sidebar />
            </div>
            <div className="d-flex flex-fill flex-column p-3">
                <h1>Moje zgody ({consentIds.length})</h1>
                <div className="flex-column">
                    {consentIds.length > 0 ? (
                        consentIds.map(({ Id }) => <Consent key={Id} consentId={Id} />)
                    ) : (
                        <p>Nie znaleziono zgód.</p>
                    )}
                </div>
            </div>
        </main>
    );
};

export default Consents;
