import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import { createEmptyApplication, getApplicationContent, createEmptyFileData, updateApplicationContent, getApplicationMetadata } from '../../services/Application';
import FileUploadComponent from './FileUploadComponent';
import HelpTooltip from './HelpTooltip';
import FileProductsConnector from './FileProductsConnector';
import FileComponentsConnector from './FileComponentsConnector';
import { v4 as uuidv4 } from 'uuid';
import InfoPopup from './InfoPopup';
import CommunicationSpot from './CommunicationSpot';
import { clientCanEdit, operatorCanEdit } from './ReadOnly';
import ReadonlyOverlay from './ReadonlyOverlay';
import CardHeader from './CardHeader';

const ApplicationStep4 = ({ communicationHandler, registerCallback }) => {
    const { accessToken, checkPermission, userId } = useAuth();
    let { step, id } = useParams();
    let navigate = useNavigate();

    const [application, setApplication] = useState(createEmptyApplication());
    const [step4, setStep4] = useState(null);

    const [exportInvoiceFiles, setExportInvoiceFiles] = useState([createEmptyFileData()]);
    const [goodsFiles, setGoodsFiles] = useState([createEmptyFileData()]);
    const [goodsFilesConnections, setGoodsFilesConnections] = useState();
    const [otherGoodsFiles, setOtherGoodsFiles] = useState([createEmptyFileData()]);
    const [otherGoodsFilesConnections, setOtherGoodsFilesConnections] = useState();
    const [productFiles, setProductFiles] = useState([createEmptyFileData()]);
    const [productFilesConnections, setProductFilesConnections] = useState([]);
    const [paymentFile, setPaymentFile] = useState(createEmptyFileData());

    const [goodsList, setGoodsList] = useState([]);
    const [componentsList, setComponentsList] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [infoPopupTrigger, setInfoPopupTrigger] = useState(0);

    const [status, setStatus] = useState(null);
    const [readOnly, setReadOnly] = useState(false);

    /******************/

    const [formValid, setFormValid] = useState(true);

    const handleStep4DataChange = (e) => {
        console.log("S4DC: [" + e.target.name + "]: " + e.target.value);
        console.log(JSON.stringify(e.target.value));

        setStep4(prevStep4 => {
            return {
                ...prevStep4,
                [e.target.name]: e.target.value
            };
        });
    }

    const handleSave = () => {
        let app = application;
        step4.exportInvoiceFiles = exportInvoiceFiles;
        step4.goodsFiles = goodsFiles;
        step4.goodsFilesConnections = goodsFilesConnections;
        step4.otherGoodsFiles = otherGoodsFiles;
        step4.otherGoodsFilesConnections = otherGoodsFilesConnections;
        step4.productFiles = productFiles;
        step4.productFilesConnections = productFilesConnections;
        step4.paymentFile = paymentFile;
        app.Step_4.Data = step4;
        console.log(JSON.stringify(step4));
        updateApplicationContent(id, app, userId, accessToken);

        setInfoPopupTrigger(infoPopupTrigger + 1);
    };

    const handleForward = async (e) => {
        e.preventDefault();
        handleSave();
        navigate('/application/edit/' + (parseInt(step) + 1) + '/' + id, { replace: true });
    };

    const handleBack = async (e) => {
        e.preventDefault();
        navigate('/application/edit/' + (parseInt(step) - 1) + '/' + id, { replace: true });
    };

    function cleanValidators() {
        setFormValid(true);
    }

    const handleValidate = () => {
        cleanValidators();
    }

    const handleFileAdd = (fileType) => {
        const newFile = createEmptyFileData();
        //newFile.id = uuidv4();
        switch (fileType) {
            case "exportInvoiceFiles":
                setExportInvoiceFiles(prevFiles => [...prevFiles, newFile]);
                break;
            case "goodsFiles":
                setGoodsFiles(prevFiles => [...prevFiles, newFile]);
                setGoodsFilesConnections(prevFilesConn => [...prevFilesConn, []]);
                break;
            case "otherGoodsFiles":
                setOtherGoodsFiles(prevFiles => [...prevFiles, newFile]);
                setOtherGoodsFilesConnections(prevFilesConn => [...prevFilesConn, []]);
                break;
            case "productFiles":
                setProductFiles(prevFiles => [...prevFiles, newFile]);
                setProductFilesConnections(prevFilesConn => [...prevFilesConn, []]);
                break;
            default:
                break;
        }
    }

    const handleFileChange = (id, name, description, fileType, index, newFile = true) => {
        const fileInfo = createEmptyFileData();
        fileInfo.id = id;
        fileInfo.name = name;
        fileInfo.description = description;
        console.log("S4 hFC: " + fileInfo.id + ", " + fileInfo.name + ", " + fileInfo.description);

        switch (fileType) {
            case "exportInvoiceFiles":
                setExportInvoiceFiles(prevFiles => {
                    const updatedFiles = [...prevFiles];
                    updatedFiles[index] = fileInfo;
                    if (newFile)
                        updatedFiles.push(createEmptyFileData());
                    return updatedFiles;
                });
                break;
            case "goodsFiles":
                setGoodsFiles(prevFiles => {
                    const updatedFiles = [...prevFiles];
                    updatedFiles[index] = fileInfo;
                    if (newFile)
                        updatedFiles.push(createEmptyFileData());
                    return updatedFiles;
                });
                break;
            case "otherGoodsFiles":
                setOtherGoodsFiles(prevFiles => {
                    const updatedFiles = [...prevFiles];
                    updatedFiles[index] = fileInfo;
                    if (newFile)
                        updatedFiles.push(createEmptyFileData());
                    return updatedFiles;
                });
                break;
            case "productFiles":
                setProductFiles(prevFiles => {
                    const updatedFiles = [...prevFiles];
                    updatedFiles[index] = fileInfo;
                    if (newFile)
                        updatedFiles.push(createEmptyFileData());
                    return updatedFiles;
                });
                break;
            case "paymentFile":
                setPaymentFile(fileInfo);
                break;
            default:
        }
    }

    const handleFileConnectionChange = (data, fileType, index) => {
        console.log("HFCC");

        switch (fileType) {
            case "goodsFiles":
                setGoodsFilesConnections(prevFilesConn => {
                    const updatedConnections = [...prevFilesConn];
                    updatedConnections[index] = data;
                    return updatedConnections;
                });
                break;
            case "otherGoodsFiles":
                setOtherGoodsFilesConnections(prevFilesConn => {
                    const updatedConnections = [...prevFilesConn];
                    updatedConnections[index] = data;
                    return updatedConnections;
                });
                break;
            case "productFiles":
                setProductFilesConnections(prevFilesConn => {
                    const updatedConnections = [...prevFilesConn];
                    updatedConnections[index] = data;
                    return updatedConnections;
                });
                break;
            default:
        }
    }

    const handleFileRemove = (fileType, index) => {
        console.log("HFR: " + fileType + ", " + index);
        switch (fileType) {
            case "exportInvoiceFiles":
                setExportInvoiceFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
                break;
            case "goodsFiles":
                setGoodsFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
                setGoodsFilesConnections(prevConns => prevConns.filter((_, i) => i !== index));
                break;
            case "otherGoodsFiles":
                setOtherGoodsFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
                setOtherGoodsFilesConnections(prevConns => prevConns.filter((_, i) => i !== index));
                break;
            case "productFiles":
                setProductFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
                setProductFilesConnections(prevConns => prevConns.filter((_, i) => i !== index));
                break;
            case "paymentFile":
                setPaymentFile(createEmptyFileData());
                break;
            default:
                break;
        }
    };

    const setApplicationMode = async () => {
        const metadata = await getApplicationMetadata(id, accessToken);
        if (metadata) {
            setStatus(metadata.status);
        }

        if (checkPermission("Obsługa wniosków")) {
            if (!operatorCanEdit(metadata.status))
                setReadOnly(true);
        }
        else {
            if (!clientCanEdit(metadata.status))
                setReadOnly(true);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("Pobieranie danych");
                const response = await getApplicationContent(id, accessToken);
                console.log(response.Step_4);
                if (response.Step_4) {
                    setApplication(response);
                    setStep4(response.Step_4.Data);
                    if (response.Step_4.Data.exportInvoiceFiles)
                        setExportInvoiceFiles(response.Step_4.Data.exportInvoiceFiles);
                    else
                        setExportInvoiceFiles([createEmptyFileData()]);

                    if (response.Step_4.Data.goodsFiles)
                        setGoodsFiles(response.Step_4.Data.goodsFiles);
                    else
                        setGoodsFiles([createEmptyFileData()]);

                    if (response.Step_4.Data.goodsFilesConnections)
                        setGoodsFilesConnections(response.Step_4.Data.goodsFilesConnections);
                    else
                        setGoodsFilesConnections([]);

                    if (response.Step_4.Data.otherGoodsFiles)
                        setOtherGoodsFiles(response.Step_4.Data.otherGoodsFiles);
                    else
                        setOtherGoodsFiles([createEmptyFileData()]);

                    if (response.Step_4.Data.goodsFilesConnections)
                        setGoodsFilesConnections(response.Step_4.Data.goodsFilesConnections);
                    else
                        setGoodsFilesConnections([]);

                    if (response.Step_4.Data.otherGoodsFilesConnections)
                        setOtherGoodsFilesConnections(response.Step_4.Data.otherGoodsFilesConnections);
                    else
                        setOtherGoodsFilesConnections([]);

                    if (response.Step_4.Data.productFiles)
                        setProductFiles(response.Step_4.Data.productFiles);
                    else
                        setProductFiles([createEmptyFileData()]);

                    if (response.Step_4.Data.productFilesConnections)
                        setProductFilesConnections(response.Step_4.Data.productFilesConnections);
                    else
                        setProductFilesConnections([]);

                    if (response.Step_4.Data.paymentFile)
                        setPaymentFile(response.Step_4.Data.paymentFile);
                }

                if (response.Step_2) {
                    setGoodsList(response.Step_2.Data.Products.filter((product) => product.exporter === "true"));
                    setProductsList(response.Step_2.Data.Products.filter((product) => product.producer === "true"));
                }

                if (response.Step_3) {
                    // setGoodsList(response.Step_2.Data.Products.filter((product) => product.exporter === "true"));
                    // setProductsList(response.Step_2.Data.Products.filter((product) => product.producer === "true"));
                    let tempComponentsList = [];
                    response.Step_3.Data.Processes.forEach(process => {
                        process.Products.forEach(product => {
                            product.Components.forEach(component => {
                                tempComponentsList.push(product.name + " > " + component.componentName);
                            });
                        });
                    });
                    setComponentsList(tempComponentsList);
                }

                setApplicationMode();

            } catch (error) {
                console.error('Failed to fetch application content:', error);
            }
        };

        fetchData();

    }, []);

    useEffect(() => {
        //handleSave();
    }, [exportInvoiceFiles, goodsFiles, goodsList, otherGoodsFiles, productFiles, productFilesConnections, paymentFile])

    return (
        <div>
            <ReadonlyOverlay readonly={readOnly}>
                <CardHeader text="Załączniki obowiązkowe" commentId="4-1" commentVisible={status !== 1} communicationHandler={communicationHandler} registerCallback={registerCallback} />
                <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                    <div className='d-flex flex-column w47'>
                        <div className='pb-1 d-flex flex-row'>
                            <strong>Faktura eksportowa lub faktura pro-forma dotycząca transakcji objętej świadectwem *</strong>
                            <HelpTooltip title="Jeżeli wystawiono więcej niż jeden dokument, załącz wszystkie" />
                        </div>

                        <div>
                            {exportInvoiceFiles.length > 0 && exportInvoiceFiles.map((file, index) => (
                                <div key={"exportInvoiceFiles_" + file.id}>
                                    <FileUploadComponent fileId={file.id} fileName={file.name} fileDescription={file.description} fileType="exportInvoiceFiles" index={index} onChange={handleFileChange} onRemove={() => handleFileRemove("exportInvoiceFiles", index)} />
                                </div>
                            )
                            )}
                        </div>

                        {exportInvoiceFiles.length < 1 && (
                        <div className='me-auto'>
                            <button type="button" className="btn btn-outline-primary h2 w7 rounded-2" onClick={() => { handleFileAdd("exportInvoiceFiles") }}>
                                Dodaj plik
                            </button>
                        </div>
                        )}
                    </div>
                </div>

                {goodsList.length > 0 && (
                    <div>
                        <CardHeader text="Załączniki do towarów" commentId="4-2" commentVisible={status !== 1} communicationHandler={communicationHandler} registerCallback={registerCallback} />
                        <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                            <div className='d-flex flex-column w47'>
                                <span className='pb-1'>W poprzednim kroku ustaliliśmy, że nie jesteś producentem następujących towarów:</span>

                                {goodsList.map((goods, index) => (
                                    <span key={"goods_" + index} className='ps-1'>
                                        &bull; {goods.productName}
                                    </span>
                                )
                                )}

                                <span className='pt-1'>
                                    Aby ustalić pochodzenie, musisz załadować do systemu <strong>faktury zakupu wskazanych towarów</strong>.
                                    Dodając każdą fakturę, oznacz towary, których ona dotyczy. Jedna faktura może dotyczyć kilku eksportowanych towarów. Jeden eksportowany towar mogłeś kupić na podstawie kilku faktur.
                                </span>
                            </div>

                            <div className='py-1'>
                                <strong>Załącz faktury zakupu towarów</strong>
                            </div>

                            <div>
                                {goodsFiles.map((file, index) => (
                                    <div className='mb-1 pb-1 border-bottom' key={"goodsFiles_" + file.id}>
                                        <FileUploadComponent fileId={file.id} fileName={file.name} fileDescription={file.description} fileType="goodsFiles" index={index} onChange={handleFileChange} onRemove={() => handleFileRemove("goodsFiles", index)} />
                                        {(goodsList.length > 0 && (
                                            <div className='pt-1'>
                                                <FileProductsConnector index={index} data={goodsFilesConnections[index]} productsList={goodsList} fileType="goodsFiles" onDataChange={handleFileConnectionChange} />
                                            </div>
                                        ))}
                                    </div>
                                )
                                )}
                            </div>

                            {goodsFiles.length < 1 && (
                            <div className='me-auto'>
                                <button type="button" className="btn btn-outline-primary h2 w7 rounded-2" onClick={() => { handleFileAdd("goodsFiles") }}>
                                    Dodaj plik
                                </button>
                            </div>)}

                            <div className='pt-2 pb-1 d-flex flex-column'>
                                <strong className='pb-1'>
                                    Inne załączniki, które mogą pomóc przy wystawieniu Świadectwa:
                                </strong>

                                <span className='ps-1'>
                                    &bull; Jeżeli na fakturze zakupu nie ma zapisu o kraju pochodzenia - dołącz dokumenty potwierdzające pochodzenie np. świadectwa pochodzenia / deklaracje o kraju pochodzenia / inne dokumenty, na których jest zapis o kraju pochodzenia
                                </span>

                                <span className='ps-1'>
                                    &bull; Jeśli zakupiłeś towar na terenie UE - dodatkowo dołącz dokumenty potwierdzające dostawę do Polski na przykład CMR-ki
                                </span>

                                <span className='pb-1 ps-1'>
                                    &bull; Jeśli zakupiłeś towar poza UE - dodatkowo dołącz dokumenty potwierdzające odprawę importową towarów
                                </span>
                            </div>

                            <div className='ps-1'>
                                {otherGoodsFiles.map((file, index) => (
                                    <div className='mb-1 pb-1 border-bottom' key={"otherGoodsFiles_" + file.id}>
                                        <FileUploadComponent fileId={file.id} fileName={file.name} fileDescription={file.description} fileType="otherGoodsFiles" index={index} onChange={handleFileChange} onRemove={() => handleFileRemove("otherGoodsFiles", index)} />
                                        {(goodsList.length > 0 && (
                                            <div className='pt-1'>
                                                <FileProductsConnector index={index} data={otherGoodsFilesConnections[index]} productsList={goodsList} fileType="otherGoodsFiles" onDataChange={handleFileConnectionChange} />
                                            </div>
                                        ))}
                                    </div>
                                )
                                )}
                            </div>
                        </div>
                    </div>
                    )}

                {productsList.length > 0 && (
                    <div>
                        <CardHeader text="Załączniki do produktów" commentId="4-3" commentVisible={status !== 1} communicationHandler={communicationHandler} registerCallback={registerCallback} />
                        <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                            <div className='d-flex flex-column w47'>
                                <span className='pb-1'>W poprzednim kroku ustaliliśmy, że jesteś producentem następujących produktów, które będą podlegały eksportowi:</span>

                                {productsList.map((product, index) => (
                                    <span key={"product_" + index} className='ps-1'>
                                        &bull; {product.productName}
                                    </span>
                                )
                                )}

                                <span className='pt-1'>
                                    Aby ustalić pochodzenie, możesz dodać do wniosku dokumenty dotyczące surowców, z których wytworzono produkty. Jeden załącznik może dotyczyć kilku eksportowanych produktów. Do jednego produktu możesz dodać kilka załączników.
                                </span>

                                <span className='py-1'>
                                    Przykładowe załączniki to:
                                </span>

                                <span className='ps-1'>
                                    &bull; zestawienie surowców / komponentów użytych w procesie produkcji z podaniem ich nazw, kodów taryfy celnej i krajów pochodzenia

                                </span>

                                <span className='ps-1'>
                                    &bull; kalkulacja kosztów wytworzenia
                                </span>

                                <span className='ps-1'>
                                    &bull; faktury zakupu surowców / komponentów użytych w procesie produkcji

                                </span>

                                <span className='ps-1'>
                                    &bull; dokumenty potwierdzające pochodzenie surowców / komponentów użytych w procesie produkcji np. świadectwa pochodzenia, deklaracje o kraju pochodzenia, inne dokumenty, na których jest zapis o kraju pochodzenia
                                </span>

                                <span className='ps-1'>
                                    &bull; inne dodatkowe dokumenty i oświadczenia wymagane na etapie weryfikacji
                                </span>
                            </div>

                            <div className='py-1'>
                                <strong>
                                    Załącz dokumenty
                                </strong>
                            </div>

                            <div className='ps-1'>
                                {productFiles.map((file, index) => (
                                <div className='mb-1 pb-1 border-bottom' key={index}>
                                    <FileUploadComponent fileId={file.id} fileName={file.name} fileDescription={file.description} fileType="productFiles" index={index} onChange={handleFileChange} onRemove={() => handleFileRemove("productFiles", index)} />
                                    {(componentsList.length > 0 && (
                                        <div className='pt-1'>
                                            <FileComponentsConnector index={index} data={productFilesConnections[index]} componentsList={componentsList} fileType="productFiles" onDataChange={handleFileConnectionChange} />
                                        </div>
                                    ))}
                                </div>
                                ))}
                            </div>

                            {productFiles.length < 1 && (
                            <div className='me-auto pb-4'>
                                <button type="button" className="btn btn-outline-primary py-2 rounded-2" style={{ width: '140px' }} onClick={() => { handleFileAdd("productFiles") }}>
                                    Dodaj plik
                                </button>
                            </div>)}
                        </div>
                    </div>
                    )}

                    <div className='mb-3'>
                        <CardHeader text="Załączniki - dodatkowe informacje" commentId="4-4" commentVisible={status !== 1} commentLocation="Załączniki - dod. info." communicationHandler={communicationHandler} registerCallback={registerCallback} />
                        <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                            <div className='d-flex flex-column w47'>
                                <div className='pb-1 d-flex flex-row'>
                                    Jeżeli wymagane są dodatkowe zapisy w treści świadectwa, załącz dokument, z którego będą one wynikać (np. akredytywa, kontrakt).
                                </div>

                                <FileUploadComponent fileId={paymentFile.id} fileName={paymentFile.name} fileDescription={paymentFile.description} fileType="paymentFile" onChange={handleFileChange} onRemove={() => handleFileRemove("paymentFile", 0)} />
                            </div>
                       </div>
                    </div>

                    {!readOnly && (
                    <div className="flex-column">
                        <form onSubmit={handleForward}>
                            <div className="d-flex flex-row ms-4 pb-4 w55">
                                <button className="btn btn-outline-primary rounded-2 me-auto w11 h2" onClick={handleBack}>
                                    Wstecz
                                </button>

                                <button type="button" className="btn btn-outline-primary rounded-2 w11 h2" onClick={handleSave}>
                                    Zapisz wersję roboczą
                                </button>

                                <button type="submit" className="btn btn-primary rounded-2 ms-1 w11 h2" disabled={!formValid}>
                                    Zapisz i przejdź dalej
                                </button>
                            </div>
                        </form>
                    </div>
                    )}

                <InfoPopup title={"Zapisano wersję roboczą wniosku."} trigger={infoPopupTrigger} />
            </ReadonlyOverlay >
        </div >
    );
};

export default ApplicationStep4;
