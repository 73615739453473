import React, { useState, useEffect } from 'react';
import ComponentSelector from './ComponentSelector';

function FileComponentsConnector({ index, data = [], componentsList, fileType, onDataChange }) {
    const [components, setComponents] = useState(componentsList);
    const [selectedComponent, setSelectedComponent] = useState();
    const [connectedComponents, setConnectedComponents] = useState([]);

    const handleSelect = (e) => {
        setSelectedComponent(e);
    }

    const handleAdd = (e) => {
        setConnectedComponents(prevComponents => {
            return [...prevComponents, selectedComponent];
        });

        setComponents(prevComponents => {
            return prevComponents.filter((component) => component !== selectedComponent);
        });

        onDataChange([...connectedComponents, selectedComponent], fileType, index);
    }

    const handleRemove = (componentName) => {
        setConnectedComponents(prevComponents => {
            return prevComponents.filter((component) => component !== componentName);
        });

        setComponents(prevComponents => {
            return [...prevComponents, componentName];
        });

        setSelectedComponent("");
        onDataChange(connectedComponents.filter((component) => component !== componentName), fileType, index);
    }

    useEffect(() => {
        console.log("uE, FCC, data: " + JSON.stringify(data));
        if (data) {
            setConnectedComponents(data);
            data.forEach(component => {
                setComponents(prevComponents => {
                    return prevComponents.filter((comp) => comp !== component);
                });
            });
        }
    }, [])

    useEffect(() => {
    }, [components]);

    return (
        <div className='w-100'>
            {(components.length > 0 ? (
            <div className='d-flex flex-row mb-1'>
                <div className='flex-fill'>
                    <ComponentSelector label="Wybierz materiały, surowce lub komponenty" componentsList={components} data={selectedComponent} onDataChange={handleSelect} />
                </div>
                <button type="button" className="btn btn-outline-primary rounded-2 mt-auto mb-0 ms-1 w7 h2" onClick={handleAdd}>
                    Dodaj
                </button>
            </div>
            ) : (
            <div className='d-flex flex-row mb-1'>
                Towary, których dotyczy ten dokument
            </div>
            ))
            }

            <div className='d-flex flex-row'>
                {connectedComponents.length > 0 ? (
                    connectedComponents.map((component, index) => (
                        <div key={"connectedComponent_" + index} className='d-flex flex-row btn btn-outline-primary border rounded-2 me-1'>
                            {component}
                            <button type="button" className="btn btn-outline-danger p-0 rounded-2 mt-auto ms-1 z-2" style={{ width: '20px', border: "0" }} onClick={() => handleRemove(component)} title="Usuń z listy">
                                X
                            </button>
                        </div>
                    ))
                ) : (
                    <div>
                        Brak połączonych materiałów, surowców lub komponentów.
                    </div>
                )}
            </div>
        </div>
    );
}

export default FileComponentsConnector;
