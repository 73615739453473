function PermissionCheckInProgress() {
    return (
        <div className="content">
        <div className="mb-4">
            <h1>Sprawdzanie uprawnień</h1>
        </div>
        <div className="card d-flex flex-row">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">...</span>
            </div>

            <div className="ms-3">
                Trwa sprawdzanie uprawnień do tej funkcji systemu.<br/>
            </div>
        </div>
    </div>

    )
}

export default PermissionCheckInProgress;