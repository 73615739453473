import React, { useEffect, useState } from 'react';
import ProductSelector from "./ProductSelector";
import ProductDetails from "./ProductDetails";
import { createEmptyFileData, createEmptyProductDetails } from '../../services/Application';
import FileUploadComponent from './FileUploadComponent';
import ValidationInfo from './ValidationInfo';
import { validateLettersDigitsSpecChars } from '../../utils/Validators';

const ProductionProcess = ({ index, name, data, productsList, onDataChange, onRemove, commentVisible = true, communicationHandler, registerCallback }) => {
    const [processData, setProcessData] = useState(data);
    const [selectedProduct, setSelectedProduct] = useState("");
    const [removedProducts, setRemovedProducts] = useState("");
    const [availableProducts, setAvailableProducts] = useState([]);
    const [processFiles, setProcessFiles] = useState([createEmptyFileData()]);
    const [productionProcessDescription, setProductionProcessDescription] = useState(data.productionProcessDescription);
    const [validators, setValidators] = useState({ productionProcessDescription: "", tariffCode: "" });

    // Funkcje zwrotne do aktualizacji stanu komponentów
    const handleProductionProcessDescriptionChange = (e) => {
        setProductionProcessDescription(e.target.value);
        onDataChange({ target: { name: name, value: { Products: processData.Products, productionProcessDescription: e.target.value, processFiles: processFiles }, index: index } });
        setValidators({ ...validators, productionProcessDescription: (validateLettersDigitsSpecChars(e.target.value, 1000, (processFiles[0].name === "")) ? "false" : "true") });
    };

    const handleProductionProcessFilesChange = () => {
        onDataChange({ target: { name: name, value: { Products: processData.Products, productionProcessDescription: productionProcessDescription, processFiles: processFiles }, index: index } });
    };

    const handleFileAdd = (fileType) => {
        switch (fileType) {
            case "processFiles":
                setProcessFiles(prevFiles => [...prevFiles, createEmptyFileData()]);
                break;
            default:
                break;
        }
    }

    const handleFileChange = (id, name, description, fileType, index, newFile = true) => {
        const fileInfo = createEmptyFileData();
        fileInfo.id = id;
        fileInfo.name = name;
        fileInfo.description = description;
        console.log("PP hFC");

        switch (fileType) {
            case "processFiles":
                setProcessFiles(prevFiles => {
                    const updatedFiles = [...prevFiles];
                    updatedFiles[index] = fileInfo;
                    if (newFile)
                        updatedFiles.push(createEmptyFileData());
                    return updatedFiles;
                });
                break;
            default:
        }
    }

    const handleFileRemove = (fileType, index) => {
        console.log("PP hFR: " + fileType + ", " + index);
        switch (fileType) {
            case "processFiles":
                setProcessFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
                break;
            default:
                break;
        }
    };

    const handleAddProductDetails = () => {
        setProcessData(prevProcessData => {
            const newProduct = createEmptyProductDetails();
            newProduct.name = selectedProduct;
            const updatedProducts = [...prevProcessData.Products, newProduct];
            onDataChange({ target: { name: name, value: { ...prevProcessData, Products: updatedProducts }, index: index } });
            return { ...prevProcessData, Products: updatedProducts };
        });
        setAvailableProducts(availableProducts.filter(prod => prod.productName !== selectedProduct));
        setSelectedProduct("");
    };

    const handleUpdateProductDetails = (productDetails, pdIndex) => {
        setProcessData(prevProcessData => {
            const updatedProducts = [...prevProcessData.Products];
            updatedProducts[pdIndex] = productDetails;
            onDataChange({ target: { name: name, value: { ...prevProcessData, Products: updatedProducts }, index: index } });
            return { ...prevProcessData, Products: updatedProducts };
        });
    };

    const handleRemoveProductDetails = (pIndex) => {
        setAvailableProducts([...availableProducts, { productName: processData.Products[pIndex].name }]);
        setRemovedProducts(prevIndexes => [...prevIndexes, pIndex]);
    };

    useEffect(() => {
        const filteredProducts = processData.Products.filter((_, index) => !removedProducts.includes(index));
        const updatedData = { ...processData, Products: filteredProducts };
        //onDataChange(updatedData, index);
        onDataChange({ target: { name: name, value: updatedData, index: index } });
    }, [removedProducts]);

    useEffect(() => {
        if (processFiles.length > 0) {
            handleProductionProcessFilesChange();
        }
        setValidators({ ...validators, productionProcessDescription: (validateLettersDigitsSpecChars(productionProcessDescription, 1000, (processFiles[0].name === "")) ? "false" : "true") });
    }, [processFiles]);

    useEffect(() => {
        const excludedNames = processData.Products.map(product => product.name);
        const filteredProducts = productsList.filter(product =>
            !excludedNames.includes(product.productName)
        );
        setAvailableProducts(filteredProducts);
        if (data.processFiles)
            setProcessFiles(data.processFiles);
        //setAvailableProducts(productsList.filter(prod => prod.productName !== processData.Products));
    }, []);

    return (
        <div className='card container-fluid shadow ms-4 my-2 py-3 px-0 w55'>
            <div>
                <div className='d-flex flex-row px-4 pb-2 w-100 border-bottom fs-4'>
                    <div>
                        <strong>{"Proces produkcji nr " + (index + 1)}</strong>
                    </div>

                    <div className='ms-auto'>
                        <button type="button" key={"ProcessRemove_" + index} className="btn btn-outline-primary rounded-2 w7 h2 ms-auto mb-0" onClick={() => onRemove(index)}>
                            Usuń proces
                        </button>
                    </div>
                </div>
            </div>

            <div className='d-flex flex-column px-4'>
                {processData.Products.map((product, pdIndex) => (
                    !removedProducts.includes(pdIndex) && (
                        <div className="pt-1 pb-3 border-bottom">
                            <ProductDetails
                                index={pdIndex}
                                key={"ProductDetails_" + index + "_" + pdIndex}
                                name={"productDetails_" + index + "_" + pdIndex}
                                data={product}
                                onDataChange={handleUpdateProductDetails}
                                onRemove={() => handleRemoveProductDetails(pdIndex)}
                                commentVisible={commentVisible}
                                communicationHandler={communicationHandler}
                                registerCallback={registerCallback}
                            />
                        </div>
                    )
                ))}

                {availableProducts.length > 0 && (
                    <div className="d-flex flex-row pt-1 pb-3" style={{ borderBottom: '1px solid #cccccc' }}>
                        <div className="flex-fill">
                            <ProductSelector name={"productSelector_" + index} data={selectedProduct} productsList={availableProducts} required="true" onDataChange={setSelectedProduct} label="Wybierz produkty, których proces produkcji chcesz opisać" tooltip={"Jeżeli nie masz odrębnego dokumentu opisującego proces produkcji - w tym kraj pochodzenia, kod taryfy celnej, musisz wypełnić pola poniżej. Jeżeli masz taki dokument, załącz go.\nProces produkcji każdego z produktów musi być opisany."} />
                        </div>

                        <div className="mt-auto">
                            <button type="button" className="btn btn-primary w7 h2 rounded-2 mt-auto ms-1 mb-0" disabled={selectedProduct === ""} onClick={handleAddProductDetails}>
                                Dodaj produkt
                            </button>
                        </div>
                    </div>
                )}

                <div className='mt-1'>
                    <div className="fs-6 mb-1">
                        <strong>Opis procesu produkcji *</strong>
                    </div>

                    <div>
                        <div className="mb-2">
                            <div className='d-flex align-items-center'>
                                <label className="form-label">Szczegółowy opis procesu produkcji </label>
                                <ValidationInfo title="Uzupełnij opis procesu produkcji (max 1000 znaków) lub dodaj załącznik z opisem." visible={validators.productionProcessDescription === "true" ? "true" : "false"} />
                            </div>
                            <textarea className="form-control my-0 h8 rounded-3" maxLength="1000" name={"Process_" + index + "_productionProcessDescription"} value={productionProcessDescription} onChange={handleProductionProcessDescriptionChange} placeholder="Szczegółowy opis procesu produkcji" invalid={validators.productionProcessDescription === "true" ? "true" : "false"} onBlur={handleProductionProcessDescriptionChange} />
                        </div>

                        <div className='mb-1'>
                            <span>lub dodaj dokumenty ze szczegółowym opisem procesu produkcji</span>
                        </div>

                        {processFiles.map((file, fIndex) => (
                            <div className={fIndex > 0 ? 'mt-2' : 'mt-0'} key={"processFiles_" + index + "_" + fIndex}>
                                <FileUploadComponent fileId={file.id} fileName={file.name} fileDescription={file.description} fileType="processFiles" index={fIndex} onChange={handleFileChange} onRemove={() => handleFileRemove("processFiles", fIndex)} />
                            </div>
                        ))}

                        {processFiles.length < 1 && (
                        <div>
                            <button type="button" className="btn btn-outline-primary w7 h2 rounded-2" onClick={() => { handleFileAdd("processFiles") }}>
                                Dodaj plik
                            </button>
                        </div>)}
                    </div>
                </div>
            </div>
        </div>);
}

export default ProductionProcess;