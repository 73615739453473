import React from 'react';

const BusinessView = ({ data }) => {
    const {
        name,
        NIP,
        street,
        building,
        number,
        postCode,
        city,
    } = data;

    return (
        <div className="d-flex flex-column flex-wrap">
            <div className='mb-1 d-flex flex-row'>
                <div>
                    <label className="form-label">Nazwa firmy</label>
                    <input type="text" className="form-control rounded-3 w27" value={name} disabled={true} />
                </div>

                <div className='ps-1'>
                    <label className="form-label">NIP</label>
                    <input type="text" className="form-control rounded-3 w19" value={NIP} disabled={true} />
                </div>
            </div>

            <div className='mb-1 d-flex flex-row'>
                <div>
                    <label className="form-label">Adres</label>
                    <input type="text" className="form-control rounded-3 w19" value={street} disabled={true} />
                </div>

                <div className='ps-1'>
                    <label className="form-label">Nr domu</label>
                    <input type="text" className="form-control rounded-3 w7" value={building} disabled={true} />
                </div>

                <div className='ps-1'>
                    <label className="form-label">Nr lokalu</label>
                    <input type="text" className="form-control rounded-3 w7" value={number} disabled={true} />
                </div>
            </div>

            <div className='d-flex flex-row'>
                <div>
                    <label className="form-label">Kod pocztowy</label>
                    <input type="text" className="form-control rounded-3 w7" value={postCode} disabled={true} />
                </div>

                <div className='ps-1'>
                    <label className="form-label">Miejscowość</label>
                    <input type="text" className="form-control rounded-3 w19" value={city} disabled={true} />
                </div>
            </div>

        </div>
    );
};

export default BusinessView;
