import React from 'react';

const ReadonlyOverlay = ({ children, readonly }) => {
  return (
    <div className="readonly-container w-100">
      {children}
      {readonly && <div className="readonly-overlay"></div>}
    </div>
  );
};

export default ReadonlyOverlay;
