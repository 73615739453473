import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { createEmptyApplication, getApplicationContent, updateApplicationContent, createEmptyFileData, getApplicationMetadata } from '../../services/Application';
import HelpTooltip from './HelpTooltip';
import ValidationInfo from './ValidationInfo';
import { validateLettersDigits } from '../../utils/Validators';
import BusinessSelector from './BusinessSelector';
import BusinessView from './BusinessView';
import { ApiHost } from '../../utils/ApiConfig';
import FileUploadComponent from './FileUploadComponent';
import InfoPopup from './InfoPopup';
import CommunicationSpot from './CommunicationSpot';
import { clientCanEdit, operatorCanEdit } from './ReadOnly';
import ReadonlyOverlay from './ReadonlyOverlay';
import CardHeader from './CardHeader';
import LoadingSpinner from './LoadingSpinner';

const tooltips = [
    "Nadaj roboczą nazwę swojemu wnioskowi np. \"wiadra plastikowe\". Możesz go zapisać i w panelu Moje wnioski, zawsze do niego wrócić.",
    "W szczególnych przypadkach, możesz wnioskować o zmianę / dopisek w danych eksportera. Każdorazowo taki wniosek zostanie zweryfikowany przez operatora.",
    "Samodzielny podpis przez przedsiębiorcę",
    "Osoba fizyczna będąca pełnomocnikiem",
    "Dane Wnioskodawcy będą widoczne w polu nr 9 wniosku o Świadectwo Pochodzenia. Jeśli jesteś osobą fizyczną reprezentującą eksportera, pomiń tę sekcję. Swoje pełnomocnictwo dodasz na późniejszym etapie.",
    "Komunikat IE-599 to dokument potwierdzający wywóz towaru, dzięki któremu możliwe jest zastosowanie zerowej stawki VAT podczas eksportu towarów. Dokument ten wydawany jest w formie elektronicznej (format XML) w ramach procedury wywozu.",
    "Wniosek możesz złożyć wyłącznie dla towarów eksportowanych z Polski."
];

const ApplicationAdd = ({ communicationHandler, registerCallback, warningsCallback }) => {
    const { accessToken, checkPermission, userId } = useAuth();
    let { step, id } = useParams();
    let navigate = useNavigate();

    const [application, setApplication] = useState(createEmptyApplication());
    const [step1, setStep1] = useState(application.Step_1.Data || []);

    const [showAlert, setShowAlert] = useState(false);

    const [formValid, setFormValid] = useState(false);
    const [nameError, setNameError] = useState('');

    const [fromPoland, setFromPoland] = useState();
    const [notFromPoland, setNotFromPoland] = useState();

    const [proxyFiles, setProxyFiles] = useState([createEmptyFileData()]);
    const [IE599File, setIE599File] = useState(createEmptyFileData());

    const [status, setStatus] = useState(1);
    const [readOnly, setReadOnly] = useState(false);

    const [validators, setValidators] = useState({
        displayName: "false",
        applicant: "",
        signedBy: "",
        agent: "",
        proxyFiles: "",
        producer: "",
        transportDispatched: "",
        IE599Files: ""
    });

    const [validateTrigger, setValidateTrigger] = useState(0);
    const [infoPopupTrigger, setInfoPopupTrigger] = useState(0);

    const handleRadioDataChange = (e) => {
        if (e.target.name === "exportFromPoland") {
            handleStep1DataChange("exportFromPoland", "true");
            handleStep1DataChange("exportNotFromPoland", "false");
        }
        if (e.target.name === "exportNotFromPoland") {
            handleStep1DataChange("exportNotFromPoland", "true");
            handleStep1DataChange("exportFromPoland", "false");
        }

        if (e.target.name === "exporterIsProducer") {
            handleStep1DataChange("exporterIsProducer", "true");
            handleStep1DataChange("exporterIsNotProducer", "false");
        }
        if (e.target.name === "exporterIsNotProducer") {
            handleStep1DataChange("exporterIsProducer", "false");
            handleStep1DataChange("exporterIsNotProducer", "true");
        }

        if (e.target.name === "transportDispatched") {
            handleStep1DataChange("transportDispatched", "true");
            handleStep1DataChange("transportNotDispatched", "false");
        }
        if (e.target.name === "transportNotDispatched") {
            handleStep1DataChange("transportDispatched", "false");
            handleStep1DataChange("transportNotDispatched", "true");
        }
    };

    const handleCheckboxDataChange = (e) => {
        handleStep1DataChange(e.target.name, e.target.checked);

        if (e.target.checked) {
            switch (e.target.name) {
                case "signedIndependently":
                    handleStep1DataChange("signedByProxy", false);
                    handleStep1DataChange("signedByAgent", false);
                    break;
                case "signedByProxy":
                    handleStep1DataChange("signedIndependently", false);
                    handleStep1DataChange("signedByAgent", false);
                    break;
                case "signedByAgent":
                    handleStep1DataChange("signedIndependently", false);
                    handleStep1DataChange("signedByProxy", false);
                    break;
                default:
                    break;
            }
            handleValidate({ target: { name: "signedBy", value: "false" } });
        }
        else {
            if (e.target.name === "editExporterCertificateData")
                handleStep1DataChange("exporterCertificateData", step1.applicantName + "\n" + step1.applicantStreet + " " + step1.applicantBuilding + (step1.applicantAppartment ? "/" + step1.applicantAppartment : "") + "\n" + step1.applicantPostCode + " " + step1.applicantCity + "\nPOLAND");
            if ((e.target.name === "signedIndependently") || (e.target.name === "signedByProxy") || (e.target.name === "signedByAgent"))
                handleValidate({ target: { name: "signedBy", value: "true" } });
        }
    };

    const handleStep1DataChange = (name, value) => {
        console.log("S1DC: [" + name + "]: " + value)
        if ((name === "applicant") || (name === "Agent")) {
            let fieldName = "applicant";
            if (name === "Agent")
                fieldName = "agent";

            try {
                const jsonValue = JSON.parse(value);

                handleStep1DataChange(fieldName + "Name", jsonValue.Name);
                handleStep1DataChange(fieldName + "NIP", jsonValue.NIP);
                handleStep1DataChange(fieldName + "Street", jsonValue.Street);
                handleStep1DataChange(fieldName + "Building", jsonValue.Building);
                handleStep1DataChange(fieldName + "Appartment", jsonValue.Appartment);
                handleStep1DataChange(fieldName + "PostCode", jsonValue.PostCode);
                handleStep1DataChange(fieldName + "City", jsonValue.City);
                handleStep1DataChange(fieldName + "Id", jsonValue.Id);

                console.log("S1DC/eECD: " + step1.editExporterCertificateData);
                if ((name === "applicant") && (step1.editExporterCertificateData === false))
                    handleStep1DataChange("exporterCertificateData", jsonValue.Name + "\n" + jsonValue.Street + " " + jsonValue.Building + (jsonValue.Appartment ? "/" + jsonValue.Appartment : "") + "\n" + jsonValue.PostCode + " " + jsonValue.City + "\nPOLAND");

            } catch (error) {
                console.error(error);
            }
        }
        else {
            setStep1(prevStep1 => {
                return {
                    ...prevStep1,
                    [name]: value
                };
            });
        }
    };


    const handleAccept = async (e) => {
        e.preventDefault();
        try {
            cleanValidators();
            if (id !== "00000000-0000-0000-0000-000000000000") {
                handleSave();
                navigate('/application/edit/2/' + id, { replace: true });
            }
            else {

                console.log("Dodawanie wniosku " + step1.displayName);
                const response = await axios.post(ApiHost + `/application/add`, {
                    user_id: userId,
                    display_name: step1.displayName
                }, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });

                if (response.status === 200) {
                    let newId = response.data.id;
                    id = newId;
                    handleSave();
                    //console.log("Dodano wniosek użytkownika [" + userId + "]");
                    navigate('/application/edit/2/' + newId, { replace: true });
                }
            }
        } catch (error) {
            console.error("Błąd podczas dodawania wniosku: ", error);
        }
    };

    function validateForm() {
        let errors = false;
        setValidators({
            displayName: "false",
            applicant: "false",
            signedBy: "false",
            agent: "false",
            proxyFiles: "false",
            producer: "false",
            transportDispatched: "false",
            IE599Files: "false"
        });

        if (step1.displayName.length === 0) {
            handleValidate({ target: { name: "displayName", value: "true" } });
            errors = true;
        }

        if (step1.applicantName.length === 0) {
            handleValidate({ target: { name: "applicant", value: "true" } });
            errors = true;
        }

        if ((step1.signedByAgent !== true) && (step1.signedByProxy !== true) && (step1.signedIndependently !== true)) {
            handleValidate({ target: { name: "signedBy", value: "true" } });
            errors = true;
        }

        if (step1.signedByAgent === true) {
            if (step1.agentName.length === 0) {
                handleValidate({ target: { name: "agent", value: "true" } });
                errors = true;
            }

            if ((step1.proxyFiles.length === 1) && (step1.proxyFiles[0].id === "")) {
                handleValidate({ target: { name: "proxyFiles", value: "true" } });
                errors = true;
            }
        }

        if ((step1.exporterIsProducer !== "true") && (step1.exporterIsNotProducer !== "true")) {
            handleValidate({ target: { name: "producer", value: "true" } });
            errors = true;
        }

        if ((step1.transportDispatched !== "true") && (step1.transportNotDispatched !== "true")) {
            handleValidate({ target: { name: "transportDispatched", value: "true" } });
            errors = true;
        }

        if (step1.transportDispatched === "true") {
            if (step1.IE599File.id === "") {
                handleValidate({ target: { name: "IE599Files", value: "true" } });
                errors = true;
            }
        }
        setValidateTrigger(validateTrigger + 1);

        return errors;
    }

    const handleSave = () => {
        console.log(JSON.stringify(step1));
        let app = application;
        app.Step_1.Data = step1;
        app.Step_1.Data.proxyFiles = proxyFiles;
        app.Step_1.Data.IE599File = IE599File;
        console.log("validadeForm");
        validateForm();
        updateApplicationContent(id, app, userId, accessToken);
        setValidateTrigger(validateTrigger + 1);
        //console.log(JSON.stringify(app) + "\nApp: " + id + "\nUserId: " + userId + "\naccessToken: " + accessToken);

        setInfoPopupTrigger(infoPopupTrigger + 1);
    };

    function showPopup() {
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    }

    const handleNameInput = (e) => {
        const value = e.target.value;

        if (/^[a-zA-Z0-9.,"'-/ąćęłńóśźżĄĆĘŁŃÓŚŹŻ ]*$/.test(value)) {
            handleStep1DataChange("displayName", value);
            handleValidate(e);
        }
    };

    const handleExporterDataInput = (e) => {
        const value = e.target.value;

        if (/^[a-zA-Z0-9.,"'-/ąćęłńóśźżĄĆĘŁŃÓŚŹŻ \n]*$/.test(value)) {
            handleStep1DataChange("exporterCertificateData", value);
        }
    };

    const handlePhoneInput = (e) => {
        const value = e.target.value;

        if (/^[0-9+/ ]*$/.test(value)) {
            handleStep1DataChange("phoneNumber", value);
        }
    };

    const handleCancel = async (e) => {
        e.preventDefault();
        navigate('/application/list', { replace: true });
    };

    function cleanValidators() {
        setNameError("");
        setFormValid(true);
    }

    const validateField = (name, value) => {
        console.log("vF: " + name);
        switch (name) {
            case "displayName":
                return validateLettersDigits(value, 160, true) ? "false" : "true";
            case "signedBy":
                return !(value === "false");
            default:
                return "true";
        }
    };

    const handleValidate = (e) => {
        const { name, value } = e.target;
        console.log("hV: " + name);
        setValidators(prevValidators => ({
            ...prevValidators,
            [name]: validateField(name, value)
        }));
        console.log(validators);
    };

    const handleFileAdd = (fileType) => {
        switch (fileType) {
            case "proxyFiles":
                setProxyFiles(prevFiles => [...prevFiles, createEmptyFileData()]);
                break;
            default:
                break;
        }
    }

    const handleFileChange = (id, name, description, fileType, index, newFile = true) => {
        const fileInfo = createEmptyFileData();
        fileInfo.id = id;
        fileInfo.name = name;
        fileInfo.description = description;
        //const file = { target: { name: fileType, value: { id: id, name: name, description: description } } }

        switch (fileType) {
            case "proxyFiles":
                setProxyFiles(prevFiles => {
                    const updatedFiles = [...prevFiles];
                    updatedFiles[index] = fileInfo;
                    if (newFile)
                        updatedFiles.push(createEmptyFileData());
                    return updatedFiles;
                });
                break;
            case "IE599File":
                setIE599File(fileInfo);
                break;
            default:
        }
    }

    const handleFileRemove = (fileType, index) => {
        switch (fileType) {
            case "proxyFiles":
                setProxyFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
                break;
            case "IE599File":
                setIE599File(createEmptyFileData());
                break;
            default:
                break;
        }
    };

    const setApplicationMode = async () => {
        const metadata = await getApplicationMetadata(id, accessToken);
        let tempStatus = status;
        if (metadata) {
            setStatus(metadata.status);
            tempStatus = metadata.status;
        }

        if (checkPermission("Obsługa wniosków")) {
            if (!operatorCanEdit(tempStatus))
                setReadOnly(true);
        }
        else {
            if (!clientCanEdit(tempStatus))
                setReadOnly(true);
        }
    }

    // Użycie useEffect do inicjalizacji danych
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id !== "00000000-0000-0000-0000-000000000000") {
                    console.log("Pobieranie danych");
                    const response = await getApplicationContent(id, accessToken);
                    //console.log(response.Step_1);
                    if (response.Step_1) {
                        setApplication(response);
                        setStep1(response.Step_1.Data || []);

                        if (response.Step_1.Data.proxyFiles)
                            setProxyFiles(response.Step_1.Data.proxyFiles);

                        if (response.Step_1.Data.IE599File)
                            setIE599File(response.Step_1.Data.IE599File);
                    }

                    const metadata = await getApplicationMetadata(id, accessToken);
                    if (metadata) {
                        setStatus(metadata.status);
                    }
                }
                else {
                    id = crypto.randomUUID();
                }

                setApplicationMode();
            } catch (error) {
                console.error('Failed to fetch application content:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        console.log("!validators! " + JSON.stringify(validators));
        let count = 0;
        for (const key in validators) {
            if (validators.hasOwnProperty(key) && ((validators[key] === "true") || validators[key] === true)) {
                count++;
            }
        }
        console.log("Count of true values: " + count);
        warningsCallback(0, count);
    }, [validators]);

    if (!step1 && (id !== "00000000-0000-0000-0000-000000000000")) {
        return <div className='p-4'><LoadingSpinner /></div>;
    }
    else
        return (
        <div className='w-100 pb-4'>
            <ReadonlyOverlay readonly={readOnly}>
                <CardHeader text="Skąd eksportujesz?" commentId="1-1" commentVisible={status !== 1} communicationHandler={communicationHandler} registerCallback={registerCallback} />
                <ReadonlyOverlay readonly={id !== "00000000-0000-0000-0000-000000000000"}>
                    <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                        <div className='d-flex flex-column pb-3 mb-2 border-bottom w47'>

                            <div className='mb-1 d-flex align-items-center mb-1'>
                                <label className="form-label"><strong>Czy eksport towaru następuje z terenu Polski? *</strong></label>
                                <HelpTooltip title={tooltips[6]} />
                            </div>

                            <div className='d-flex flex-column'>
                                <div className="form-check form-check-inline mb-1">
                                    <input className="form-check-input" type="radio" name="exportFromPoland" id="exportFromPoland" checked={step1.exportFromPoland === "true"} onChange={handleRadioDataChange} />
                                    <label className="form-check-label" htmlFor="exportFromPoland">TAK</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="exportNotFromPoland" id="exportNotFromPoland" checked={step1.exportNotFromPoland === "true"} onChange={handleRadioDataChange} />
                                    <label className="form-check-label" htmlFor="exportNotFromPoland">NIE</label>
                                </div>
                            </div>

                        </div>
                        {(step1.exportFromPoland === "true") ? (
                            <div>
                                <div className='mb-0 d-flex align-items-center'>
                                    <label className="form-label mb-0">Nazwa robocza wniosku *</label>
                                    <HelpTooltip title={tooltips[0]} />
                                    <ValidationInfo title="Wypełnienie pola jest obowiązkowe. Maksymalna ilość znaków - 160" visible={validators.displayName} />
                                </div>

                                <input
                                    type="text"
                                    className="form-control my-0 rounded-3 w47"
                                    maxLength="160"
                                    name="displayName"
                                    value={step1.displayName}
                                    onChange={handleNameInput}
                                    placeholder="Nazwa wniosku, np. Wniosek - szpadle tytanowe"
                                    onBlur={handleValidate}
                                    required={true}
                                    invalid={validators.displayName}
                                />
                                {nameError && (<div className="text-danger">{nameError}</div>)}
                            </div>
                        ) : ((step1.exportNotFromPoland === "true") && (
                            <div>
                                Brak możliwości złożenia wniosku.<br />
                                Świadectwo pochodzenia wydawane jest tylko dla towarów i produktów eksportowanych z terenu Polski.
                            </div>
                        ))}
                    </div>
                </ReadonlyOverlay>
                <div>
                    {step1.displayName && (step1.displayName.length > 0) && (
                        <div>
                            <CardHeader text="Podaj dane eksportera / wnioskodawcy" commentId="1-2" commentVisible={status !== 1} commentLocation="Dane eksportera / wnioskodawcy" communicationHandler={communicationHandler} registerCallback={registerCallback} />
                            <div className='card container-fluid shadow ms-4 pb-3 px-4 pt-3 w55'>
                                <div className='d-flex flex-column'>
                                    <div className='mb-1'>
                                        <strong>Dane eksportera / wnioskodawcy</strong>
                                    </div>

                                    <div className='mb-2 w47' key="applicant">
                                        <BusinessSelector
                                            label="Wybierz z listy firmę, którą reprezentujesz lub dodaj nową"
                                            name="applicant"
                                            id="applicant"
                                            data={JSON.stringify({ "Name": step1.applicantName, "NIP": step1.applicantNIP, "Street": step1.applicantStreet, "Building": step1.applicantBuilding, "Appartment": step1.applicantAppartment, "PostCode": step1.applicantPostCode, "City": step1.applicantCity, "Id": step1.applicantId })}
                                            required={"true"}
                                            onDataChange={handleStep1DataChange}
                                            validateTrigger={validateTrigger}
                                            onValidate={handleValidate}
                                        />
                                    </div>

                                    <div className='mb-2'>
                                        <BusinessView data={{ name: step1.applicantName, NIP: step1.applicantNIP, street: step1.applicantStreet, building: step1.applicantBuilding, number: step1.applicantAppartment, postCode: step1.applicantPostCode, city: step1.applicantCity }} />
                                    </div>

                                    {step1.editExporterCertificateData != null && (
                                        <div className="mb-1 d-flex align-items-center">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="editExporterCertificateData" name="editExporterCertificateData" checked={step1.editExporterCertificateData} onChange={handleCheckboxDataChange} />
                                                <label className="form-check-label" htmlFor="editExporterCertificateData">
                                                    Chcę edytować widoczne na świadectwie dane eksportera pobrane z rejestru GUS
                                                </label>
                                            </div>

                                            <HelpTooltip title={tooltips[1]} />
                                        </div>
                                    )}

                                    {step1.editExporterCertificateData != null && (
                                        <div className="d-flex flex-row border-bottom w47">
                                            <div className='d-flex flex-column pb-3 w47'>
                                                <textarea
                                                    className="form-control my-0 rounded-3 h6"
                                                    maxLength="280"
                                                    name="exporterCertificateData"
                                                    value={
                                                        (step1.editExporterCertificateData && step1.exporterCertificateData) ?
                                                            step1.exporterCertificateData
                                                            :
                                                            step1.applicantName + "\n" + step1.applicantStreet + " " + step1.applicantBuilding + (step1.applicantAppartment ? "/" + step1.applicantAppartment : "") + "\n" + step1.applicantPostCode + " " + step1.applicantCity + "\nPOLAND"
                                                    }
                                                    onChange={handleExporterDataInput}
                                                    disabled={!step1.editExporterCertificateData ? true : false} />
                                                <span className="ms-auto small">pole nr 1 na świadectwie</span>
                                            </div>
                                        </div>
                                    )}

                                    <div className='my-1 d-flex align-items-center'>
                                        <strong>Wniosek będzie PODPISANY przez:</strong>
                                        {(validators.signedBy === "true" || validators.signedBy === true) &&
                                            (<ValidationInfo title="Wybierz jedną z opcji" visible={validators.signedBy} />)
                                        }
                                    </div>

                                    <div className='pb-2 border-bottom'>
                                        <div className="mb-1 d-flex align-items-center">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="signedIndependently" name="signedIndependently" checked={step1.signedIndependently} onChange={handleCheckboxDataChange} />
                                                <label className="form-check-label" htmlFor="signedIndependently">
                                                    Samodzielnie przez przedsiębiorcę, pracownika lub członka organów eksportera
                                                </label>
                                            </div>
                                            <HelpTooltip title={tooltips[2]} />
                                        </div>
                                        {step1.signedIndependently &&
                                            (<div className='ps-2-5 mb-1'>
                                                <strong>UWAGA: Jeżeli osoba podpisująca nie figuruje w rejestrach (KRS, CEIDG), to na etapie składania podpisanego wniosku poprosimy Cię o dołączenie jej pełnomocnictwa.</strong>
                                            </div>)}

                                        <div className="mb-1 d-flex align-items-center">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="signedByProxy" name="signedByProxy" checked={step1.signedByProxy} onChange={handleCheckboxDataChange} />
                                                <label className="form-check-label" htmlFor="signedByProxy">
                                                    Osobę fizyczną będącą pełnomocnikiem eksportera
                                                </label>
                                            </div>
                                            <HelpTooltip title={tooltips[3]} />
                                        </div>
                                        {step1.signedByProxy &&
                                            (<div className='ps-2-5 mb-1'>
                                                <strong>UWAGA: Na etapie składania podpisanego wniosku poprosimy Cię o dołączenie jej pełnomocnictwa.</strong>
                                            </div>)}

                                        <div className="mb-1 d-flex align-items-center">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="signedByAgent" name="signedByAgent" checked={step1.signedByAgent} onChange={handleCheckboxDataChange} />
                                                <label className="form-check-label" htmlFor="signedByAgent">
                                                    Firmę (np. agencja celna), której Eksporter zlecił obsługę procesów celnych
                                                </label>
                                            </div>
                                            <HelpTooltip title={tooltips[4]} />
                                        </div>
                                        {step1.signedByAgent &&
                                            (<div>
                                                <div className='ps-2-5 mb-1'>
                                                    <strong>UWAGA: Na etapie składania podpisanego wniosku poprosimy Cię o dołączenie pełnomocnictwa dla osoby podpisującej wniosek.</strong>
                                                </div>

                                                <div className='ps-2-5 mb-1'>
                                                    <BusinessSelector
                                                        label="Wybierz z listy firmę, która w imieniu eksportera składa wniosek"
                                                        name="Agent"
                                                        id="Agent"
                                                        data={JSON.stringify({ "Name": step1.agentName, "NIP": step1.agentNIP, "Street": step1.agentStreet, "Building": step1.agentBuilding, "Appartment": step1.agentAppartment, "PostCode": step1.agentPostCode, "City": step1.agentCity, "Id": step1.agentId })}
                                                        required={step1.signedByAgent}
                                                        onDataChange={handleStep1DataChange}
                                                        validateTrigger={validateTrigger}
                                                        onValidate={handleValidate} />
                                                </div>

                                                <div className='ps-2-5 mb-1 d-flex align-items-center'>
                                                    <strong>Załącz dokumenty reprezentacji Eksportera przez firmę Wnioskodawcy</strong>
                                                    {(validators.proxyFiles === "true" || validators.proxyFiles === true) &&
                                                        (<ValidationInfo title="Załącz dokumenty reprezentacji Eksportera przez firmę Wnioskodawcy" visible={true} />)
                                                    }
                                                </div>

                                                {proxyFiles && proxyFiles.map((file, fIndex) => (
                                                    <div className='ps-2-5 pb-1' key={"proxyFiles_" + file.id}>
                                                        <FileUploadComponent fileId={file.id} fileName={file.name} fileDescription={file.description} fileType="proxyFiles" index={fIndex} onChange={handleFileChange} onRemove={() => handleFileRemove("proxyFiles", fIndex)} />
                                                    </div>
                                                ))}

                                                {proxyFiles.length < 1 && (
                                                    <div className='ps-0 pb-1 me-auto'>
                                                        <button type="button" className="btn btn-outline-primary py-2 rounded-2 w7" onClick={() => { handleFileAdd("proxyFiles") }}>
                                                            Dodaj plik
                                                        </button>
                                                    </div>)}

                                                {/* <div className='ms-4 mb-4'>
                                        <FileUploadComponent index={0} fileId={proxyFile.id} fileName={proxyFile.name} fileDescription={proxyFile.description} fileType="proxyFile" onChange={handleFileChange} onRemove={() => handleFileRemove("paymentFile")} />
                                    </div> */}
                                            </div>)}
                                    </div>
                                    <div className='mt-2'>
                                        <label className="form-label">Numer telefonu do kontaktu w sprawie wniosku (opcjonalnie)</label>
                                        <input type="text" className="form-control my-0 rounded-3 w23" name='phoneNumber' maxLength="50" value={step1.phoneNumber} onChange={handlePhoneInput} placeholder="Numer telefonu" required={false} />
                                    </div>
                                </div>
                            </div>
                        </div>)}

                    {step1.displayName && step1.displayName.length > 0 && (
                        <div>

                            <div className='ms-4 mt-4 mb-0 d-flex align-items-center small-caps w55'>
                                <h3>KIM JEST EKSPORTER?</h3>
                                {(validators.producer === "true" || validators.producer === true) &&
                                    (<ValidationInfo title="Wybierz jedną z opcji" visible={true} />)
                                }
                                <div className='ms-auto'>
                                    <CommunicationSpot commentId="1-3" location="Kim jest eksporter?" isVisible={status !== 1} onClickHandler={communicationHandler} registerCallback={registerCallback} />
                                </div>
                            </div>

                            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                                <div className='d-flex flex-row'>

                                    <div className='card rounded-2 w23 p-0'>
                                        <div className='card-body pb-2'>
                                            <div className='d-flex flex-row'>
                                                <div>
                                                    <input type="radio" className='form-check-input me-1' name="exporterIsProducer" id="exporterIsProducer" checked={step1.exporterIsProducer === "true"} onChange={handleRadioDataChange} />
                                                </div>

                                                <div>
                                                    <label className="form-check-label" htmlFor="exporterIsProducer">
                                                        <strong>JEST PRODUCENTEM</strong><br />
                                                        co najmniej części eksportowanych artykułów. Eksportuje produkty wyprodukowane w swojej firmie oraz (opcjonalnie) towary zakupione od innych przedsiębiorców.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='ms-1 card rounded-2 w23 p-0'>
                                        <div className='card-body
                                        '>
                                            <div className='d-flex flex-row'>
                                                <div>
                                                    <input type="radio" className='form-check-input me-1' name="exporterIsNotProducer" id="exporterIsNotProducer" checked={step1.exporterIsNotProducer === "true"} onChange={handleRadioDataChange} />
                                                </div>

                                                <div>
                                                    <label className="form-check-label" htmlFor="exporterIsNotProducer">
                                                        <strong>NIE JEST PRODUCENTEM</strong><br />
                                                        eksportowanych artykułów. Eksportuje towary, których nie wyprodukował. Kupił je od innych przedsiębiorców.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <CardHeader text="Transport" commentId="1-4" commentVisible={status !== 1} communicationHandler={communicationHandler} registerCallback={registerCallback} />
                            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                                <div className='d-flex flex-column'>
                                    <div className='mb-1 d-flex align-items-center'>
                                        Czy towar został już wysłany? *
                                        {(validators.transportDispatched === "true" || validators.transportDispatched === true) &&
                                            (<ValidationInfo title="Wybierz jedną z opcji" visible={true} />)
                                        }
                                    </div>

                                    <div className="form-check form-check-inline mb-1">
                                        <input className="form-check-input" type="radio" name="transportDispatched" id="transportDispatched" checked={step1.transportDispatched === "true"} onChange={handleRadioDataChange} />
                                        <label className="form-check-label" htmlFor="transportDispatched">TAK</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="transportNotDispatched" id="transportNotDispatched" checked={step1.transportNotDispatched === "true"} onChange={handleRadioDataChange} />
                                        <label className="form-check-label" htmlFor="transportNotDispatched">NIE</label>
                                    </div>
                                </div>

                                {step1.transportDispatched === "true" && (
                                    <div className='pt-1 mt-3 border-top'>
                                        <div className='mb-1 d-flex align-items-center'>
                                            <strong>Załącz dokument "Komunikat IE599"</strong>
                                            <HelpTooltip title={tooltips[5]} />
                                            {(validators.IE599Files === "true" || validators.IE599Files === true) &&
                                                (<ValidationInfo title="Załącz dokument" visible={true} />)
                                            }
                                        </div>

                                        <div className='mb-1'>
                                            <FileUploadComponent index={0} fileId={IE599File.id} fileName={IE599File.name} fileDescription={IE599File.description} fileType="IE599File" onChange={handleFileChange} onRemove={() => handleFileRemove("IE599File", 0)} />
                                        </div>
                                        <strong>UWAGA: Jeżeli Komunikaty IE599 pochodzą z różnych wysyłek, to musisz zawnioskować o odrębne Świadectwo Pochodzenia dla każdej wysyłki objętej komunikatem.</strong>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {!readOnly && (
                        <div className="flex-column ms-4 w55">
                            <form onSubmit={handleAccept}>
                                <div className="d-flex flex-column flex-wrap">
                                    <div className="d-flex flex-row mt-3 w-100">
                                        <button className="btn btn-outline-primary rounded-2 me-auto w11 h2" onClick={handleCancel}>
                                            Wyjdź
                                        </button>

                                        {(step1.exportFromPoland === "true") && (
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary rounded-2 w11 h2"
                                                onClick={handleSave}
                                            >
                                                Zapisz wersję roboczą
                                            </button>
                                        )}

                                        {(step1.exportFromPoland === "true") && (
                                            step1.displayName === "" ? (
                                                <button type="submit" className="btn btn-primary rounded-2 ms-1 w11 h2" disabled={true}>
                                                    Zapisz i przejdź dalej
                                                </button>
                                            ) : (
                                                <button type="submit" className="btn btn-primary rounded-2 ms-1 w11 h2">
                                                    Zapisz i przejdź dalej
                                                </button>
                                            )
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </div>

                <InfoPopup title={"Zapisano wersję roboczą wniosku."} trigger={infoPopupTrigger} />
            </ReadonlyOverlay>
        </div>
    );
};

export default ApplicationAdd;
