import React, { useState, useEffect } from 'react';
import Sidebar from '../components/layout/Sidebar'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import UserData from '../components/common/UserData';
import InfoPopup from '../components/common/InfoPopup';
import { useAuth } from '../context/AuthContext';
import AccessDenied from '../components/common/AccessDenied';
import CardHeader from '../components/common/CardHeader';

function UserEdit() {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [infoPopupTrigger, setInfoPopupTrigger] = useState(0);
    let { id } = useParams();
    let navigate = useNavigate();
    const { checkPermission } = useAuth();

    const handleResize = () => {
        if (window.innerWidth >= 992) {
            setIsSidebarVisible(true);
        } else {
            setIsSidebarVisible(false);
        }
    };

    const handleBack = () => {
        navigate('/user/list/', { replace: true });
    }

    const handleAfterSave = () => {
        handleBack();
        setInfoPopupTrigger(infoPopupTrigger + 1);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        //handleResize(); // Wywołanie przy montowaniu komponentu

        return () => window.removeEventListener('resize', handleResize); // Oczyszczenie przy demontowaniu
    }, []);

    return (
        <main className="d-flex flex-row" style={{ height: '100vh' }}>
            <Sidebar />
            {checkPermission("Zarządzanie użytkownikami") ? (
                <div>
                    <CardHeader text="Edycja użytkownika" onlyText="true" />
                    <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                        <div className='d-flex flex-column'>
                            <div className='mb-1'>
                                W tym widoku możesz zarządzać danymi użytkownika systemu.
                            </div>
                            {id && (
                                <UserData userId={id} handleBack={handleBack} handleAfterSave={handleAfterSave} />
                            )}
                        </div>

                        <InfoPopup title={id === "00000000-0000-0000-0000-000000000000" ? "Dodano użytkownika" : "Zapisano zmiany danych użytkownika"} trigger={infoPopupTrigger} />
                    </div>
                </div>
            ) : (
                <AccessDenied />
            )}
        </main>
    );
}

export default UserEdit;
