function LoadingSpinner({text = "Pobieranie danych"}) {
    return (
        <div className="d-flex flex-row">
            <div className="spinner-border spinner-border-sm align-self-center" role="status">
                <span className="visually-hidden">...</span>
            </div>

            <div className="ms-1">
                {text}
            </div>
        </div>
    )
}

export default LoadingSpinner;