import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { ApiHost } from '../../utils/ApiConfig';

function FileUploadComponent({ fileId, fileName, fileDescription, fileType, index, onChange, onRemove, readOnly = "false" }) {
    const [file, setFile] = useState(null);
    const [selectedFileName, setFileName] = useState(fileName || "Wybierz plik");
    const [selectedFileDescription, setFileDescription] = useState(fileDescription || "");

    const fileInputRef = useRef(null);

    const handleUpload = async () => {
        if (!file) {
            alert('Proszę wybrać plik przed wysłaniem.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(ApiHost + '/common/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Plik został pomyślnie przesłany, ID pliku:' + response.data.file_id + ", nazwa pliku: " + file.name);

            setFileName(file.name);

            console.log('onChange (fId, fN/sFN, sFD):' + response.data.file_id + ", " + file.name + "/" + selectedFileName + ", " + selectedFileDescription);

            onChange(response.data.file_id, file.name, selectedFileDescription, fileType, index);
        } catch (error) {
            console.error('Wystąpił błąd podczas przesyłania pliku:', error);
        }
    };

    const downloadFile = async (fileId) => {
        try {
            const response = await fetch(ApiHost + `/common/download/${fileId}`);
            if (!response.ok) {
                throw new Error('Błąd podczas pobierania pliku');
            }
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            console.error('Błąd podczas pobierania pliku:', error);
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFile(file);
        }
    };

    const handleFileDescriptionChange = (e) => {
        setFileDescription(e.target.value);
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        if (file) {
            handleUpload();
        }
    }, [file]);

    useEffect(() => {
        if (fileId && readOnly === "false") {
            onChange(fileId, selectedFileName, selectedFileDescription, fileType, index, false);
        }
    }, [selectedFileDescription, readOnly]);

    return (
        <div className='flex-fill'>
            {(!fileId) ? (
                <div className='d-flex justify-content-between'>
                    <input type="file" onChange={handleFileChange} id="fileInput" ref={fileInputRef} style={{ display: "none" }} />
                    <div>
                        <button className='btn btn-outline-primary ms-0 w7 h2' onClick={handleButtonClick}>Dodaj plik</button>
                    </div>

                    <div className='d-flex flex-row'>
                        <div className='flex-fill ms-1'>
                            <input type="text" className="form-control my-0 rounded-3 w26" maxLength="160" name="fileDescription" value={selectedFileDescription} onChange={handleFileDescriptionChange} placeholder="Opcjonalny opis pliku (max 160 znaków)" />
                        </div>

                        <div>
                            <button style={{ display: "none" }} onClick={handleUpload} disabled={!file}>Prześlij plik</button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='d-flex justify-content-between'>
                    <div className='flex-fill'>
                        <label><a href="#" onClick={() => { downloadFile(fileId) }}>{selectedFileName}</a></label>
                    </div>

                    <div className='d-flex flex-row'>
                        <div className='flex-fill ms-1'>
                            <input type="text" className="form-control rounded-3 w23" maxLength="160" name="fileDescription" value={selectedFileDescription} onChange={handleFileDescriptionChange} disabled={(readOnly === "true")} placeholder={(readOnly === "true") ? "" : "Opcjonalny opis pliku (max 160 znaków)"} />
                        </div>

                        {readOnly === "false" && (
                            <div>
                                <button className='btn btn-outline-primary ms-1 p-0 w2 h2 fs-4' onClick={onRemove}><span className='bi-trash'></span></button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default FileUploadComponent;
