import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import Product from './Product';
import ProductSummary from './ProductsSummary';
import { createEmptyApplication, getApplicationContent, updateApplicationContent, createEmptyProductData, createEmptySummary, getApplicationMetadata } from '../../services/Application';
import InfoPopup from './InfoPopup';
import { clientCanEdit, operatorCanEdit } from './ReadOnly';
import ReadonlyOverlay from './ReadonlyOverlay';
import CardHeader from './CardHeader';

const ApplicationStep2 = ({ communicationHandler, registerCallback }) => {
    const { accessToken, checkPermission, userId } = useAuth();
    let { step, id } = useParams();
    let navigate = useNavigate();

    const [formValid, setFormValid] = useState(true);

    const [application, setApplication] = useState(createEmptyApplication());
    const [applicant, setApplicant] = useState({ producerName: "", producerCountry: "", producerStreet: "", producerBuilding: "", producerAppartment: "", producerPostCode: "", producerCity: "" });
    const [products, setProducts] = useState(application.Step_2.Data.Products); //useState([{ productName: "", quantity: "", producer: "", exporter: "", boughtInEU: "", boughtOutsideEU: "", producerName: "" }]);
    const [productsSummary, setProductsSummary] = useState(createEmptySummary); //useState({ editSummaries: false });
    const [exportOnly, setExportOnly] = useState(false);
    const [infoPopupTrigger, setInfoPopupTrigger] = useState(0);

    const [status, setStatus] = useState(null);
    const [readOnly, setReadOnly] = useState(false);

    const handleProductDataChange = (index, name, value) => {
        setProducts(prevProducts => {
            const updatedProducts = [...prevProducts];
            updatedProducts[index] = {
                ...updatedProducts[index],
                [name]: value
            };
            return updatedProducts;
        });
    };

    const handleRemoveProduct = (indexToRemove) => {
        setProducts(prevProducts => prevProducts.filter((product, index) => index !== indexToRemove));
    };

    const setApplicationMode = async () => {
        const metadata = await getApplicationMetadata(id, accessToken);
        if (metadata) {
            setStatus(metadata.status);
        }

        if (checkPermission("Obsługa wniosków")) {
            if (!operatorCanEdit(metadata.status))
                setReadOnly(true);
        }
        else {
            if (!clientCanEdit(metadata.status))
                setReadOnly(true);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("Pobieranie danych");
                const response = await getApplicationContent(id, accessToken);
                console.log(response.Step_2);
                if (response.Step_2) {
                    setProducts(response.Step_2.Data.Products || []);
                    setProductsSummary(response.Step_2.Data.Summary || {});
                    setApplication(response);

                    if (response.Step_1) {
                        setApplicant({
                            producerName: response.Step_1.Data.applicantName,
                            producerCountry: "PL",
                            producerStreet: response.Step_1.Data.applicantStreet,
                            producerBuilding: response.Step_1.Data.applicantBuilding,
                            producerAppartment: response.Step_1.Data.applicantAppartment,
                            producerPostCode: response.Step_1.Data.applicantPostCode,
                            producerCity: response.Step_1.Data.applicantCity
                        });
                        setExportOnly(response.Step_1.Data.exporterIsNotProducer);
                    }
                }

                setApplicationMode();

            } catch (error) {
                console.error('Failed to fetch application content:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        //console.log("AS2 - uE");
        updateProductsSummaries();
    }, [products, productsSummary.editSummaries, productsSummary.totalQuantity, productsSummary.totalNetWeight, productsSummary.totalGrossWeight]);

    const updateProductsSummaries = () => {
        if (productsSummary.editSummaries === "true") return;
        let newProductsSummaryEdit = "";
        let newQuantitySummaryEdit = "";
        let i = 1;
        products.forEach(product => {
            //console.log("PN: " + product.productName);
            newProductsSummaryEdit += i + ". " + product.productName + " (" + product.originCountry + ")\n";
            newQuantitySummaryEdit += product.quantity + " " + (product.unit ? product.unit : "") + (product.quantityDesc ? (" " + product.quantityDesc) : "") + "\n";
            i++;
        });

        if ((productsSummary.totalQuantity) && (productsSummary.totalQuantity.length > 0))
            newQuantitySummaryEdit += "Total: " + productsSummary.totalQuantity + "\n";
        if ((productsSummary.totalNetWeight) && (productsSummary.totalNetWeight.length > 0))
            newQuantitySummaryEdit += "Total net weight: " + productsSummary.totalNetWeight + "\n";
        if ((productsSummary.totalGrossWeight) && (productsSummary.totalGrossWeight.length > 0))
            newQuantitySummaryEdit += "Total gross weight: " + productsSummary.totalGrossWeight + "\n";

        handleProductSummaryDataChange("productsSummaryEdit", newProductsSummaryEdit);
        handleProductSummaryDataChange("quantitySummaryEdit", newQuantitySummaryEdit);
    }

    const handleProductSummaryDataChange = (name, value) => {
        //console.log("hPSDC: [" + name + "]: " + value)
        setProductsSummary(prevSummary => {
            return {
                ...prevSummary,
                [name]: value
            };
        });
    };

    const handleAddProduct = () => {
        // Funkcja obsługująca dodawanie nowego produktu
        setProducts(prevProducts => [...prevProducts, createEmptyProductData()]);
    };

    const handleSave = () => {
        // Funkcja zwracająca dane komponentów Product w postaci JSON
        const productDataJSON = products.map(product => ({
            productName: product.productName,
            quantity: product.quantity,
            unit: product.unit,
            quantityDesc: product.quantityDesc,
            tariffCode: product.tariffCode,
            originCountry: product.originCountry,
            producerName: product.producerName,
            producerCountry: product.producerCountry,
            producerStreet: product.producerStreet,
            producerBuilding: product.producerBuilding,
            producerAppartment: product.producerAppartment,
            producerPostCode: product.producerPostCode,
            producerCity: product.producerCity,
            producer: product.producer,
            exporter: product.exporter,
            boughtInEU: product.boughtInEU,
            boughtOutsideEU: product.boughtOutsideEU,
            importClearanceFile: product.importClearanceFile
        }));
        //console.log(JSON.stringify(productDataJSON));

        const productsSummaryJSON = {
            totalQuantity: productsSummary.totalQuantity,
            totalNetWeight: productsSummary.totalNetWeight,
            totalGrossWeight: productsSummary.totalGrossWeight,
            transportDetails: productsSummary.transportDetails,
            productsRemarks: productsSummary.productsRemarks,
            productsSummaryEdit: productsSummary.productsSummaryEdit,
            quantitySummaryEdit: productsSummary.quantitySummaryEdit,
            editSummaries: productsSummary.editSummaries
        };

        let app = application;
        app.Step_2.Data.Products = productDataJSON;
        app.Step_2.Data.Summary = productsSummaryJSON;
        updateApplicationContent(id, app, userId, accessToken);

        setInfoPopupTrigger(infoPopupTrigger + 1);
    };

    const handleForward = async (e) => {
        // Funkcja obsługująca przekierowanie do następnego kroku
        e.preventDefault();
        handleSave();
        navigate('/application/edit/' + (parseInt(step) + 1) + '/' + id, { replace: true });
    };

    const handleBack = async (e) => {
        // Funkcja obsługująca przekierowanie do poprzedniego kroku
        e.preventDefault();
        navigate('/application/edit/' + (parseInt(step) - 1) + '/' + id, { replace: true });
    };

    function cleanValidators() {
        // Funkcja czyszcząca walidatory
        setFormValid(true);
    }

    const handleValidate = () => {
        // Funkcja walidująca formularz
        cleanValidators();
    };

    return (
        <div className='pb-4'>
            <ReadonlyOverlay readonly={readOnly}>
                <CardHeader text="Produkty / Towary - dodawanie" commentId="2-1" commentVisible={status !== 1} communicationHandler={communicationHandler} registerCallback={registerCallback} />
                <div className='ms-4 w55'>
                    <div>
                        W tej sekcji prosimy Cię o określenie które produkty wyprodukowałeś we własnej firmie, a które towary zakupiłeś od innych przedsiębiorców. Ponieważ w ramach jednej transakcji możesz eksportować produkty własnej produkcji oraz towary zakupione od innych przębiorców, w tej sekcji odrębnie podajesz dane dla produktów i towarów.<br />
                        <strong> Pamiętaj, aby uzupełnić poszczególne pola w języku, w jakim mają się pojawić na świadectwie.</strong>
                    </div>
                    <div className='d-flex flex-column w47'>
                        <div>

                        </div>
                        {(products.length === 0) && (
                            <div className="d-flex flex-row">
                                <button type="button" className="btn btn-primary w11 h2 rounded-2 ms-auto" onClick={handleAddProduct}>
                                    Dodaj produkt
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                {products.map((product, index) => (
                    <div key={index} className='card container-fluid shadow ms-4 mt-2 px-4 py-3 w55'>
                        <div className='d-flex flex-column w47'>
                            <Product
                                index={index}
                                data={product}
                                applicant={applicant}
                                exportOnly={exportOnly}
                                onDataChange={(name, value) => handleProductDataChange(index, name, value)}
                                onRemove={() => handleRemoveProduct(index)}
                                commentVisible={status !== 1}
                                communicationHandler={communicationHandler}
                                registerCallback={registerCallback} />
                        </div>
                    </div>
                ))}

                {((!readOnly) && (products.length > 0)) && (
                    <div className="d-flex flex-row ms-4 mt-2 w55">
                        <button type="button" className="btn btn-primary w11 h2 rounded-2 ms-auto" onClick={handleAddProduct}>
                            Dodaj kolejny produkt
                        </button>
                    </div>
                )}

                {(productsSummary) && (
                    <div>
                        <CardHeader text="Podsumowanie" commentId="2-2" commentVisible={status !== 1} communicationHandler={communicationHandler} registerCallback={registerCallback} />
                        <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                            <div className='d-flex flex-column w47'>
                                <ProductSummary data={productsSummary} onDataChange={(name, value) => handleProductSummaryDataChange(name, value)} communicationHandler={communicationHandler} registerCallback={registerCallback} />
                            </div>
                        </div>
                    </div>
                )}

                {!readOnly && (
                    <div className="flex-column mt-3">
                        <form onSubmit={handleForward}>
                            <div className="d-flex flex-row ms-4 w55">
                                <button className="btn btn-outline-primary rounded-2 me-auto w11 h2" onClick={handleBack}>
                                    Wstecz
                                </button>

                                <button type="button" className="btn btn-outline-primary rounded-2 w11 h2" onClick={handleSave}>
                                    Zapisz wersję roboczą
                                </button>

                                <button type="submit" className="btn btn-primary rounded-2 ms-1 w11 h2" disabled={!formValid}>
                                    Zapisz i przejdź dalej
                                </button>
                            </div>
                        </form>
                    </div>
                )}
                <InfoPopup title={"Zapisano wersję roboczą wniosku."} trigger={infoPopupTrigger} />
            </ReadonlyOverlay>
        </div>
    );
};

export default ApplicationStep2;
