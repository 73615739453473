import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { ApiHost } from '../../utils/ApiConfig';
import LoadingSpinner from './LoadingSpinner';

const CertificatePreview = ({ applicationId, className }) => {
    const { accessToken } = useAuth();
    const [imageUrl, setImageUrl] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(10);
    const [useCache, setUseCache] = useState(true);
    const [trigger, setTrigger] = useState(0);
    const [isRefreshing, setIsRefreshing] = useState(false);

    const downloadFile = async () => {
        try {
            if (!applicationId)
                throw "Brak identyfikatora wniosku";
               
            const response = await axios.get(ApiHost + `/certificate/preview/` + applicationId + '/' + currentPage + '/' + (useCache ? 'True' : 'False'),
                { responseType: 'blob' },
                { headers: { Authorization: `Bearer ${accessToken}` } });

            try {
                const nextResponse = await axios.get(ApiHost + `/certificate/preview/` + applicationId + '/' + (currentPage + 1) + '/True',
                { responseType: 'blob' },
                { headers: { Authorization: `Bearer ${accessToken}` } });
            } catch (error) {
                setPageCount(currentPage);
            }

            return response.data;
        } catch (error) {
            console.error('Błąd podczas pobierania pliku:', error);
            throw error;
        }
    };

    const handlePrevPage = async () => {
        if (currentPage > 0)
            setCurrentPage(currentPage - 1);
    }

    const handleNextPage = async () => {
        setCurrentPage(currentPage + 1);
    }

    useEffect(() => {
        const loadImage = async () => {
            setIsRefreshing(true);
            const blob = await downloadFile();
            const url = URL.createObjectURL(blob);
            setImageUrl(url);
            setIsRefreshing(false);
        };

        loadImage();

        if (!useCache)
            setUseCache(true);

        return () => {
            if (imageUrl) {
                URL.revokeObjectURL(imageUrl);
            }
        };
    }, [currentPage, trigger]);

    return (
        <div className={className}>
            <div>
                {imageUrl ? <img src={imageUrl} className='img-fluid border border-2 mb-2' alt="Dynamicznie pobrany obraz" /> :
                    <div className="d-flex align-items-center justify-content-center p-4" role="status">
                        <strong>Generowanie podglądu...</strong>
                    </div>
                }
            </div>

            <div className='d-flex justify-content-between w-100'>
                {(currentPage > 0 ) ? (
                <button className="btn btn-outline-primary h2 rounded-2 w9" onClick={handlePrevPage}>
                    Poprzednia strona
                </button>
                ) : (
                <button className="btn btn-outline-primary h2 rounded-2 w9" disabled>
                    Poprzednia strona
                </button>
                )}

                {!isRefreshing ? (
                <button className="btn btn-outline-primary h2 rounded-2 w11" onClick={() => {setUseCache(false);setTrigger(prevTrigger => prevTrigger + 1); console.log("trigger: " + (trigger + 1));}}>
                    Odśwież
                </button>
                ) : (
                <div className="btn btn-outline-primary h2 rounded-2 w11">
                    <LoadingSpinner />
                    {/* <span className='spinner-border spinner-border-sm me-1'></span>
                    <span>Ładowanie</span> */}
                {/* <button className="btn btn-outline-primary h2 rounded-2 spinner-border" style={{ width: '180px' }} disabled>
                    Odśwież
                </button> */}
                </div>
                )}

                {(currentPage < pageCount) ? (
                <button type="submit" className="btn btn-outline-primary h2 rounded-2 w9" onClick={handleNextPage}>
                    Następna strona
                </button>
                ) : (
                <button type="submit" className="btn btn-outline-primary h2 rounded-2 w9" disabled>
                    Następna strona
                </button>
                )}
            </div>
        </div>
    );
};

export default CertificatePreview;
