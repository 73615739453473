import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import DataTable from './DataTable';
import { useNavigate } from 'react-router-dom';
import { ApiHost } from '../../utils/ApiConfig';

const BusinessList = () => {
    const { accessToken } = useAuth();
    const { userId } = useAuth();
    const [businesses, setBusinesses] = useState([]);
    let navigate = useNavigate();

    var data = [];
    const itemsPerPage = 5;

    useEffect(() => {
        const getBusinesses = async () => {
            try {
                console.log("Pobieranie listy powiązanych firm dla użytkownika: " + userId)
                const response = await axios.post(ApiHost + '/business/list', {
                    user_id: userId
                }, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                    .then(response => {
                        const receivedBusinesses = response.data;
                        data = [];
                        receivedBusinesses.forEach(business => {
                            const address = business.Data.Street + " " + business.Data.Building + (business.Data.Number ? "/" + business.Data.Number : "") + ", " + business.Data.PostCode + " " + business.Data.City;
                            data.push({ "Firma": business.Name, "Adres": address, "NIP": business.NIP, "Action": business.Id });
                            console.log(address);
                        });
                        setBusinesses(data);
                    })
                    .catch(error => console.error('Błąd pobierania listy powiązanych firm: ', error));
            } catch (error) {
                console.error('Błąd pobierania listy powiązanych firm: ', error);
            }
        };

        getBusinesses();
    }, [userId, accessToken]);

    return (
        <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
            <div className='d-flex flex-column w47'>
                <div className='d-flex flex-row'>
                    <div>
                        W tym widoku możesz zarządzać listą firm, które reprezentujesz lub reprezentują Ciebie w procesie obsługi wniosku.
                    </div>
                    <div className='ms-auto mb-1'>
                        <button className="btn btn-primary" style={{ width: '140px' }} onClick={() => { navigate('/business/add', { replace: true }); }}>Dodaj</button>
                    </div>
                </div>
            </div>
            <DataTable data={businesses} itemsPerPage={itemsPerPage} columnCount={4} columnHeaders={['Firma', 'Adres', 'NIP', 'Operacje']} initialSortColumn="Firma" />
        </div>
    );
};

export default BusinessList;
