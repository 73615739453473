import CardHeader from "./CardHeader";

function AccessDenied() {
    return (
        <div className="content">
            <CardHeader text="Brak dostępu" onlyText="true" />
            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-row w47'>
                    <div>
                        <span className="bi-exclamation-triangle" style={{ fontSize: "2rem" }}></span><br />
                    </div>

                    <div className="ms-1">
                        Nie posiadasz dostępu do tej funkcji systemu.<br />
                        W celu rozwiązania problemu skontaktuj się z administratorem.
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AccessDenied;