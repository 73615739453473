import React, { useEffect, useState } from 'react';
import Sidebar from '../components/layout/Sidebar';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ApiHost } from '../utils/ApiConfig';

const AddConsent = () => {
    const { accessToken } = useAuth();
    const { userId } = useAuth();
    let navigate = useNavigate();
    let { consentType } = useParams();
    let consentTypes = ["Zgoda RODO", "Zgoda handlowa"];
    const [consentText, setConsentText] = useState('');

    // if (!isLoggedIn)
    //     navigate('/Login', { replace: true });

    const handleAccept = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(ApiHost + `/users/consent/add`, {
                consent_type: consentType,
                user_id: userId
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            if (response.status === 200) {
                console.log("Dodano zgodę: " + consentTypes[consentType - 1])
                navigate('/account/consents', { replace: true });
            }
        } catch (error) {
            console.error("Błąd podczas dodawania zgody:", error);
        }
    };

    const handleCancel = async (e) => {
        e.preventDefault();
        navigate('/account/consents', { replace: true });
    };

    useEffect(() => {
        const getConsentText = async () => {
            try {
                console.log("Pobieranie tekstu zgody")
                await axios.post(ApiHost + '/consent/get_text', {
                    consent_type: consentType
                }, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                    .then(response => {
                        setConsentText(response.data.consent_text);
                    })
                    .catch(error => console.error('Błąd pobierania consentIds:', error));
            } catch (error) {
                console.error('Błąd dostępu do chronionej metody', error);
            }
        };

        getConsentText();
    }, [userId, accessToken]); // Zależności useEffect


    console.log("Akceptacja zgody: " + consentType);

    return (
        <main className="d-flex flex-nowrap" style={{ height: '100vh' }}>
            <div className="d-flex flex-column">
                <Sidebar />
            </div>
            <div className="d-flex flex-fill flex-column p-3">
                <h1>Akceptacja zgody</h1>
                <div className='card container-fluid'>
                    <h5>{consentTypes[consentType - 1]}</h5>
                    {(consentText === '') && (
                        <div className="me-auto spinner-border" role="status">
                            <span className="visually-hidden">Pobieranie danych</span>
                            <br />
                        </div>
                    )
                    }
                    {(consentText !== '') && (
                        <div>
                            <div className="mb-3">{consentText}</div>
                            <div className="flex-column">
                                <form onSubmit={handleAccept}>
                                    <div className="d-flex flex-row flex-wrap">
                                        <button className="btn btn-outline-primary py-2 rounded-2 me-auto" style={{ width: '140px' }} onClick={handleCancel}>
                                            Anuluj
                                        </button>
                                        <button type="submit" className="btn btn-primary py-2 rounded-2 ms-auto" style={{ width: '140px' }}>
                                            Zaakceptuj
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )
                    }
                </div>
            </div>
        </main>
    );
};

export default AddConsent;
