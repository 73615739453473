import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { ApiHost } from '../../utils/ApiConfig';
import ValidationInfo from './ValidationInfo';
import { validateNIP, validateDigits } from '../../utils/Validators';
import InfoPopup from './InfoPopup';

const GusSearch = ({visible, handleSearch, handleCancel}) => {
    const { accessToken } = useAuth();
    const { userId } = useAuth();

    const [nip, setNip] = useState('');
    const [departments, setDepartments] = useState([]);
    const [nipInvalid, setNipInvalid] = useState();
    const [warningTrigger, setWarningTrigger] = useState(0);
    const [loading, setLoading] = useState(false);

    const validateNip = (e) => {
         let invalid = validateNIP(e.target.value) ? "false" : "true";
         setNipInvalid(invalid);
    };

    const handleNipInput = (e) => {
        if ((e.target.value.length == 0) || (validateDigits(e.target.value, 10)))
            setNip(e.target.value);
    }

    const handleNipKeyPress = (e) => {
        if ((e.key === 'Enter') && (nipInvalid === "false")) {
            e.preventDefault();
            gusSearch();
        }
    };

    const gusSearch = async () => {
        try {
            setLoading(true);
            console.log("Pobieranie danych z GUS [" + nip + "]")
            await axios.post(ApiHost + '/gus/search', {
                nip: nip
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
                .then(response => {
                    fillForm(response);
                    setLoading(false);
                })
                .catch(error => handleGusError());
        } catch (error) {
            console.warn('Błąd pobierania danych z GUS: ', error);
        }
    };

    function fillForm(response) {
        const data = response.data;
        if (data.Departments.length == 0){
            handleSearch({
                "Name": data.Name,
                "NIP": nip,
                "Street": data.Street,
                "Building": data.Building,
                "Appartment": (data.Number ? data.Number : ""),
                "PostCode": data.PostCode,
                "City": data.City,
                "Id": "00000000-0000-0000-0000-000000000000"
            });

            handleCancel();
        }
        else {
            let hq = {
                    "Name": data.Name,
                    "NIP": nip,
                    "Street": data.Street,
                    "Building": data.Building,
                    "Appartment": (data.Number ? data.Number : ""),
                    "PostCode": data.PostCode,
                    "City": data.City,
                    "Id": "00000000-0000-0000-0000-000000000000"
                }
            setDepartments([hq, ...data.Departments]);
        }
    }

    const handleSelect = (e) => {
        const data = JSON.parse(e.target.value);

        handleSearch({
            "Name": data.Name,
            "NIP": nip,
            "Street": data.Street,
            "Building": data.Building,
            "Appartment": (data.Number ? data.Number : ""),
            "PostCode": data.PostCode,
            "City": data.City,
            "Id": "00000000-0000-0000-0000-000000000000"
        });

        handleCancel();
    }

    function handleGusError(){
        setWarningTrigger(warningTrigger + 1);
    }

    useEffect(() => {
        validateNip({target: {value: nip}});
    }, [nip]);

    useEffect(() =>{
        if (visible){
            setDepartments([]);
        }
    }, [visible]);

    useEffect(() =>{
        if (loading)
            console.log("Pobieranie danych z GUS...");
        else
            console.log("Zakończono");
    }, [loading]);

    if (visible)
        return (
            <div>
                <div className='card mt-1'>
                    <div className='w-100'>
                        <div className='d-flex align-items-center'>
                            <label className="form-label">NIP *</label>
                            {(nipInvalid === "true") && (<ValidationInfo title="Wpisz poprawny numer NIP (10 cyfr)." visible={true} />)}
                        </div>

                        <div className="d-flex flex-row">
                            <input
                                type="text"
                                className="form-control my-0 w7"
                                maxLength="10"
                                value={nip}
                                onChange={handleNipInput}
                                onKeyDown={handleNipKeyPress}
                                placeholder="Numer NIP, np. 5422838585"
                                onBlur={validateNip}
                                required={true}
                                invalid={nipInvalid}
                            />

                        {
                            loading && (
                            <div className='btn btn-outline-primary ms-1 d-flex align-items-center w11'>
                                Pobieranie danych&nbsp;
                                <div className="ms-auto spinner-border" style={{height: "18px", width: "18px"}} />
                            </div>
                            )
                        }
                        {!loading && ((nipInvalid !== "false") ? (
                            <button
                                className="btn btn-primary ms-1 my-0 w11"
                                type="button"
                                disabled
                                onClick={() => {}}>
                                Pobierz dane z GUS
                            </button>
                            ) : (
                            <button
                                className="btn btn-primary ms-1 my-0 w11"
                                type="button"
                                onClick={() => {gusSearch();}}>
                                Pobierz dane z GUS
                            </button>))
                        }
                            <button
                                className="btn btn-outline-primary ms-1 my-0 w7"
                                type="button"
                                onClick={handleCancel}>
                                Zamknij
                            </button>
                        </div>
                    </div>

                    {departments.length > 0 && (<div className='d-flex flex-row'>
                    <div className='w-100 mt-1'>
                        <div className='d-flex flex-row mb-1'>
                            <label className="form-label mb-0">Wybierz oddział</label>
                        </div>

                        <div className='d-flex flex-row'>
                            <select
                                className="form-control my-0 rounded-3 w-100" 
                                name="businessSelect" 
                                onChange={handleSelect} 
                            >
                                <option value="">Wybierz z listy</option>
                                {departments.map(department => (
                                <option key={department.Regon} value={JSON.stringify(department)}>
                                        {department.Name} ({department.City})
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    </div>)}
                    {/* <input type="text" className="form-control my-0 py-2 rounded-3" maxLength="10" value={nip} onChange={handleNipInput} pattern='^[0-9]{10}$' placeholder="np. 5422838585" /> */}

                </div>

                <InfoPopup title={"Błąd pobierania danych z GUS. Sprawdź numer NIP."} trigger={warningTrigger} warning={true} />
            </div>
        )
}

export default GusSearch;
