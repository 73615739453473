import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import DataTableApp from './DataTableApp';
import { useNavigate } from 'react-router-dom';
import { ApiHost } from '../../utils/ApiConfig';

const ApplicationList = ({ operator = false }) => {
    const { accessToken } = useAuth();
    const { userId } = useAuth();
    const [applications, setApplications] = useState([]);
    let navigate = useNavigate();

    var data = [];
    const itemsPerPage = 10;

    useEffect(() => {
        const getApplications = async () => {
            let apiMethod = operator ? '/application/assigned' : '/application/list';
            try {
                console.log("Pobieranie listy wniosków dla użytkownika: " + userId)
                const response = await axios.post(ApiHost + apiMethod, {
                    user_id: userId
                }, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                    .then(response => {
                        const receivedApplications = response.data;
                        data = [];
                        receivedApplications.forEach(application => {
                            data.push({ "Nazwa": application.Name, "Status": application.StatusName, "Data utworzenia": application.CreateDate, "Action": application.Id });
                        });
                        setApplications(data);
                    })
                    .catch(error => console.error('Błąd pobierania listy wniosków: ', error));
            } catch (error) {
                console.error('Błąd pobierania listy wniosków: ', error);
            }
        };

        getApplications();
    }, [userId, accessToken]);

    return (
        <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
            <div className='d-flex flex-column w47'>
                {!operator && (
                    <div className='d-flex flex-row'>
                        <div>
                            W tym widoku możesz śledzić postęp obsługi swoich wniosków.
                        </div>
                        <div className='ms-auto mb-1'>
                            <button className="btn btn-primary w7" onClick={() => { navigate('/application/add', { replace: true }); }}>Utwórz wniosek</button>
                        </div>
                    </div>
                )}
                <DataTableApp data={applications} itemsPerPage={itemsPerPage} columnCount={4} columnHeaders={['Nazwa', 'Status', 'Data utworzenia', 'Action']} />
            </div>
        </div>
    );
};

export default ApplicationList;
